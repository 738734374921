import React, { useEffect } from 'react';
import './ToTopBtn.scss'
import ToTopBtnIcon from '../../assets/images/toTopBtn.svg'
import inCartIcon from '../../assets/images/Car-Nav.png'
import { useDispatch } from 'react-redux';
import { setCartModal } from '../../../../store/slices/contextUI';
import { useNavigate, useParams } from 'react-router';

const ToTopBtn = () => {
  const tradeName = useParams().tradeName
  const platform = useParams().tradeName
  const navigate = useNavigate()
  const dispatch = useDispatch()
    const [scrollTop, setScrollTop] = React.useState(false);
    React.useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 340) {
          setScrollTop(true);
        } else {
          setScrollTop(false);
        }
      });
      return () => window.removeEventListener("scroll", () => {});
    }, []);
    const bottomToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    const bottomToBottom = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
    });
    };

    return (<>
        <div 
          className='toTopBtn'
          style={{
            display: scrollTop ? "flex" : "none",
            right: '15px'
            }}>
          <img
            src={inCartIcon}
            style={{filter: 'brightness(0) invert(1)'}}
            width={30}
            alt="To top"
            onClick={() => {if (tradeName !== undefined) {
  if (platform !== undefined) {navigate(`/newOrder/${tradeName}`)} else {navigate(`/newOrder/${tradeName}`)} window.scrollTo(0, 0)
            } else dispatch(setCartModal(true))}}
          />
        </div>
        <div className='toTopBtn'
          style={{
            display: scrollTop ? "flex" : "none",
          }}
        >
          <img    
            src={ToTopBtnIcon}
            alt="To top"
            onClick={bottomToTop}
          />
        </div>
        <div className='toTopBtn'
          style={{
            display: scrollTop ? "flex" : "none",
            transform: 'rotate(180deg)',
            right: '123px'
          }}
        >
          <img    
            src={ToTopBtnIcon}
            alt="To top"
            onClick={bottomToBottom}
          />
        </div>
        </>
    );
}

export default ToTopBtn;