import React, { useEffect, useState } from 'react'
import './ItemCardModal.scss'
import CloseIcon from '../../assets/images/Close_Green.svg'
import HeartIcon from '../../assets/images/heart-linear-card-deactive.svg'
import HeartIconActive from '../../assets/images/CartHeartActive.svg'
import { setAllItemsFavorites, setFavoritesElemQuantityValue } from '../../../../store/slices/allItemsFavorites'
import { setCartElemInfo, setCartModal, setItemModal } from '../../../../store/slices/contextUI'
import { ReactComponent as SuccessStock } from '../../assets/images/SuccessStock.svg'
import { ReactComponent as OutOfStock } from '../../assets/images/DeniedStock.svg'
import { ReactComponent as StockTBC } from '../../assets/images/StockTBC.svg'
import { useDispatch, useSelector } from 'react-redux'
import ImageModal from '../../ModalWindows/ImageModal/ImageModal'
import ItemCounterModal from './ItemCounterModal/ItemCounterModal'
import inCartIcon from '../../assets/images/Car-Nav.png'
import { Snackbar } from '@mui/material'
import useFindInCart from '../../../../hooks/useFindInCart'
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit'

const ItemCardModal = ({ card }) => {
  const dispatch = useDispatch();
  const { name, marketPriceCP, SPOPrice, img, stock, dias, weightUnits, weightValue, brand, businessType, category, desc } = card
  const favorites = useSelector((state) => state.allItemsFavorites.allItemsFavorites)
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const [bigPicture, setBigPicture] = useState(false)
  
  const inCart = useFindInCart(card);
  

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const totalPrice = allItemsCart.map((el) => el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const IVA = allItemsCart.map((el) => (el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit))) - (parseInt(el.IVACP) !== 0 ? 
  ((el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(parseInt(el.IVACP))) : 0))
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  console.log(card);
  
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)


  return (
    <div className='ItemCardModal' onClick={() => dispatch(setItemModal({item: {},  isShow: false}))}>
      <div className='ItemCardModal__content' onClick={(e) => e.stopPropagation()}>
        {bigPicture && <ImageModal img={img} setClose={setBigPicture} />}
        <div className='ItemCardModal__content__header'>
          {!dias && stock != '0' && stock != '1' &&
          (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info TBC'>
              <StockTBC className='contentCard__body__subtitle__info__svg ' />
              Confirmar
            </div>
          )}
          <div className='ItemCardModal__content__header__img'>
            <img src={card?.img} alt="" onClick={() => setBigPicture(true)}/>
          </div>
          <div className='ItemCardModal__content__header__data'>
            <div className='ItemCardModal__content__header__data__title'>
              {name}
            </div>
            <div className='ItemCardModal__content__header__data__subtitle'>
              <div className='stock'>
                {weightValue + ' ' + weightUnits}
              </div>
              {dias ? (
                <div
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info dias'>{dias} days</div>
              ) : stock == '1' ? (
                <div
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info stock'>
                  <SuccessStock className='contentCard__body__subtitle__info__svg' />
                  stock
                </div>
              ) : stock == '0' && (
                <div 
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info outOfStock'>
                  <OutOfStock className='contentCard__body__subtitle__info__svg' />
                  stock
                </div>
              )}
            </div>
          </div>
          <div className='ItemCardModal__content__header__btn'>
            <img src={CloseIcon} alt="X" onClick={() => dispatch(setItemModal({item: {},  isShow: false}))}/>
          </div>
        </div>
        <div className='ItemCardModal__content__body'>
          <div className='ItemCardModal__content__body__buttons'>
            <div className='ItemCardModal__content__body__buttons__price'>{marketPriceCP}&euro;<span>/ {card.currentUnit}</span></div>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <img 
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' viewBox='0 0 24 24'%3E%3Cpath fill='%235fc56e' d='m13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.457l5.11 2.788a3.5 3.5 0 1 1-.958 1.755'/%3E%3C/svg%3E"
                alt=""
                style={{cursor: 'pointer'}}
                onClick={() => {navigator.clipboard.writeText(`https://tpoapp.com/item/${card._id}`);handleClick()}}
              />
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Link of product was copied"
                sx={{ border: '3px solid #5fc56e', borderRadius: '8px', background: '#fff' }}
              />
              <img 
                src={favorites.find((items) => {
                  return items.sku === card.sku
                }) ? HeartIconActive : HeartIcon} 
                alt="" 
                onClick={() => {
                  dispatch(setAllItemsFavorites(card))
                  dispatch(setFavoritesElemQuantityValue({ value: 1, elem: card }))
                  // setAddFavorite(!addFavorite)
                }}
              />
            </div>
          </div>
          <div className='ItemCardModal__content__body__counter'>
          <ItemCounterModal item={card} /> 
            <div className='ItemCardModal__content__body__counter__price'>
              <span>Total item: {ivaSwitch ? (card.marketPriceCP* (useFindItemByUnit(card, inCart?.currentUnit || card.unit?.split(',')[0])*(inCart?.quantity || card.quantity))).toFixed(2) +'€' : ((card.marketPriceCP* (useFindItemByUnit(card, inCart?.currentUnit || card.unit?.split(',')[0])*(inCart?.quantity || card.quantity)))/(100+parseInt(card.IVACP))*100).toFixed(2) +'€'}</span>
              
              {/* <span>Total cart: {totalCart.toFixed(2)}&euro;</span> */}
            </div>
          </div>
          <div className='ItemCardModal__content__body__info'>
            <div
              className='ItemCardModal__content__body__info__text'
              >Additional information: <span style={{fontWeight: '600'}}>{desc}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Brand: <span>{brand}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Category: <span>{category}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >BusinessType: <span>{businessType}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Structure: <span></span>
            </div>
          </div>
        </div>
        <div className='ItemCardModal__content__footer'>
          <div 
          className='ItemCardModal__content__footer__continueBtn'
          onClick={() => {
            dispatch(setItemModal({item: {},  isShow: false}))
            dispatch(setCartModal(true))
          }} 
          style={{color: '#5fc56e', borderColor: '#5fc56e'}}
        >
          {/* Cart */}
          <img src={inCartIcon} alt="" />
          <pre> </pre>
          {ivaSwitch ? totalPrice.toFixed(2) : IVA.toFixed(2)}€
        </div>
        <div 
          className='ItemCardModal__content__footer__addCartBtn'
          onClick={() => dispatch(setItemModal({item: {},  isShow: false}))} 
          // style={{color: inCart ? '#fff' :'#5fc56e', background: inCart ? '#5fc56e' : '#fff'}}
        >
          Continue
        </div>
        </div>
      </div>
    </div>
  )
}

export default ItemCardModal