import React from 'react'
import './OrderItemMobile.scss'
import CompliteSquare from '../../../../../assets/images/Complite-Square-2.svg'
import TimeSquare from '../../../../../assets/images/Time-Square-2.svg'
import CloseSquare from '../../../../../assets/images/Close-Square-2.svg'
import ArrowRight from '../../../../../assets/images/ArrowRight.svg'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { setItemsCart } from '../../../../../../../store/slices/allItemsCart'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


const OrderItemMobile = ({ item }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function convertTimestampToDate(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate;
      }
      console.log(item);
    const totalPrice = item.items.map((el) => el.marketPriceCP * el.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const handleRepidPedido = (item) => {
    //   console.log(item.items);
        dispatch(setItemsCart(item.items))
        handleClick()
    }
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    };
    function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
    }
    const action = (
    <div className='Snackbar'>
        <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        >
        <CloseIcon fontSize="small" />
        </IconButton>
    </div>
    );
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
    setOpen(true);
    };

    return (
    <div className='OrderItemMobile'>
        <Snackbar
            className='Snackbar'
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Artículos agregados al carrito"
            action={action}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            TransitionComponent={SlideTransition}
        />
        <div className='OrderItemMobile__header'>
            <img src={item.status.name === 'Emitido' ? CompliteSquare : item.status.name === 'Waiting' ? TimeSquare : CloseSquare} alt="" />
            <div className='OrderItemMobile__header__info'>
                <div className='OrderItemMobile__header__info__status'>
                    <p>{item.status.name}</p>
                    <div>Fecha: <span>{convertTimestampToDate(item.date)}</span></div>
                </div>
                <div className='OrderItemMobile__header__info__id'>
                    Pedido: <span>{item?._id}</span>
                </div>
            </div>
        </div>
        <div className='OrderItemMobile__body'>
            <div 
                className='OrderItemMobile__body__btn'
                onClick={() => handleRepidPedido(item)}
            >
                Repetir pedido
            </div>
            <div className='OrderItemMobile__body__btn' onClick={() => navigate(`/order/${item._id}`)}>
                Mas informacion
                <img src={ArrowRight} alt="" />
            </div>
            <span>{totalPrice?.toFixed(2)}€</span>
        </div>
    </div>
    )
}

export default OrderItemMobile