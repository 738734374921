import React, { useState, useEffect, useMemo } from 'react'
import './LoginModal.scss'
import { setAccModal } from '../../../../store/slices/accModal';
import telegramIcon from '../../assets/images/logos_telegram.svg';
import whatsupIcon from '../../assets/images/WhatsupIconLogin.svg';
import googleIcon from '../../assets/images/GoogleIconLogin.png';
import { Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import closeIcon from './../../assets/images/closeElem.svg';
import axios from 'axios';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { setAccName, setAccPhone, setUserAcc } from '../../../../store/slices/accountData';
import { setIsLogin } from '../../../../store/slices/contextUI';
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'; 
import Snackbar from '@mui/material/Snackbar';

const LoginModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const openModal = useSelector((state) => state.accModal.accModal.Modal)
    const userAcc = useSelector((state) => state.accountData.userAcc);
    
    const handleGoToHome = () => {
        // navigate('/home')
        dispatch(setAccModal(''))
    }

    const [phone, setPhone] = useState(localStorage.getItem('savedPhone') ? localStorage.getItem('savedPhone') : '');
    // const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [rememberCheck, setRememberCheck] = React.useState(true)

    const acceptLogin = async () => {
        let informData =  {
            phone: phone.replace('+', ''),
            password: password,
        };
        // console.log(informData);
        const responseLogin = await axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
        dispatch(setUserAcc(responseLogin.data))
        // dispatch(setAccPhone(responseLogin.data.phone))
        // dispatch(setAccName(responseLogin.data.name))
        dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
        
        if (responseLogin.data.length !== 0) {
            // navigate('/home');
            dispatch(setAccModal(''));
            if (rememberCheck) { 
            const targetDate = new Date(); // Get the current date
            targetDate.setDate(targetDate.getDate() + 30); // Add 30 days
            localStorage.setItem('LoginRules', JSON.stringify({dateLogin: new Date(), refreshLogin: targetDate}))
            localStorage.setItem('LoginData', JSON.stringify({phoneLogin: phone.replace('+', ''), passwordLogin: password}))}
          } else {
            handleClick({ vertical: 'top', horizontal: 'center' })
          }
    }
    const handleSetPhone = (inputPhone) => {
        setPhone(inputPhone.replace('+', ''))
    }



    const [stateSnake, setStateSnake] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    const { vertical, horizontal, open } = stateSnake;

    const handleClick = (newState) => {
      setStateSnake({ ...newState, open: true });
    };

    const handleClose = () => {
      setStateSnake({ ...stateSnake, open: false });
    };
    const [handleShowPass, setHandleShowPass] = useState(false)

    const handleRegister = () => {
        dispatch(setAccModal('Register'))
    }

    return (
        <div className='LoginModal'>
            <div className='LoginModal__background' onClick={handleGoToHome}></div>
            <div className='LoginModal__backgroundModal'>
                <div className="LoginModal__header">
                    <div className='LoginModal__header__title'>Acceder a la cuenta personal</div>
                    <div onClick={handleGoToHome}>
                        <img src={closeIcon} alt="X" width={20} height={20} style={{cursor: 'pointer'}}/>
                    </div>
                </div>
                <form action="POST" className='LoginModal__form'>
                    <Box sx={{ width: 500 }}>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={open}
                            onClose={handleClose}
                            message="User not found"
                            key={vertical + horizontal}
                        />
                    </Box>
                    <label className='label' htmlFor="emailPlacholder">El número de móvil</label>
                    <PhoneInput
                        className='LoginModal__form__phoneInput'
                          defaultCountry="es"
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                        />
                    <label className='label' htmlFor="passwordPlaceholder">Contraseña</label>
                    <input
                        type={handleShowPass ? 'text' : 'password'}
                        id='passwordPlaceholder'
                        onChange={(e) => setPassword(e.target.value)}
                        className='LoginModal__form__input'
                    />
                    <div
                        style={{color: handleShowPass && '#5FC56E'}}
                        className='LoginModal__form__showpassword'
                        onClick={() => {setHandleShowPass(!handleShowPass)}}
                    >Mostrar contraseña 🔑</div>
                    <div className='LoginModal__form__remember'>
                        <div>
                            <Checkbox
                                checked={rememberCheck}
                                sx={{
                                '& .MuiSvgIcon-root': { fontSize: 36, color: '#5FC56E' } }} onChange={() => setRememberCheck(!rememberCheck)}/>
                            <label htmlFor="rememberMe">Mantenerme en la cuenta</label>
                        </div>
                        <a href="#">Forgot your password ?</a>
                    </div>
                    <div className='LoginModal__button'>
                        <div
                            className='LoginModal__button__login__active'
                            onClick={() => acceptLogin()}
                        >
                            Entra
                        </div>
                        <div
                            className='LoginModal__button__login'
                            onClick={() => handleRegister()}
                        >
                            Regístrate
                        </div>
                    </div>
                </form>
                <div className='LoginModal__footer'>
                    {/* <div className='LoginModal__footer__text'>or continue through</div>
                    <div className='LoginModal__footer__icons'>
                        <img src={telegramIcon} width={40} height={40}/>
                        <img src={whatsupIcon} width={40} height={40}/>
                        <img src={googleIcon} width={40} height={40}/>
                    </div> */}
                    {/* <div className=''>
                        <div className='' onClick={handleRegister}>Register</div>
                        <div className=''>
                            After registering on the site, you will be able to track the status of orders, personal account and other features.
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
)}

export default LoginModal
