import React, { useEffect, useState } from 'react';
import './ComplitedPayCart.scss'
import CopyIcon from '../../assets/images/CopyIcon.svg'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../assets/images/closeElem.svg';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ComplitedPayCart = () => {
    const navigate = useNavigate();
    const PayNumber = useSelector(state => state.contextUI.idLastOrder)
    const tradeName = useParams().tradeName
    const platformParams = useParams().platform
    const [open, setOpen] = React.useState(false);
    
    
    const handleClick = (PayNumber) => {
        navigator.clipboard.writeText(PayNumber)
        setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };
    const [userData, setUserData] = useState({})
    

    const action = (
      <>
        <IconButton
        sx={{
            background: '#ffffff',
    
    }}
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="close" />
        </IconButton>
      </>
    );
    

    return (
        <div className='ThanksModal'>
            <h1 className='ThanksModal__h1'>Gracias por tu pedido 🤝</h1>
            <p className='ThanksModal__p'>Has realizado el pedido correctamente👍<br/>
            Pronto recibirás la confirmación de nuestro almacén de que ya está siendo procesado!</p>
            <div className='ThanksModal__numberContainer'>
                <h3 className='ThanksModal__h3'>El número del pedido</h3>
                <span className='ThanksModal__numberContainer__payNumber'>#{PayNumber?.slice(-6)}</span>
                <img src={CopyIcon} alt="copy" onClick={() => {
                    navigator.clipboard.writeText(PayNumber?.slice(-6))
                    handleClick()
                }}/>
            </div>
            <div className='ThanksModal__backBtn' onClick={() => {
                if (tradeName !== undefined) {
                  if (platformParams !== undefined) {
                    navigate(`/home/${platformParams}/${tradeName}`)}
                    else {navigate(`/category/${tradeName}`)}
                } else {navigate('/home')}
              }}>Volverme a lista de favoritos</div>
            <div className='ThanksModal__backBtn' onClick={() => navigate('/account/' + tradeName)}>Mis pedidos</div>
        
            <div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Id was copied"
                  action={action}
                  sx={{ zIndex: 100 }}
                />
            </div>
        </div>
    );
}

export default ComplitedPayCart;