import { createSlice } from '@reduxjs/toolkit'
import { initialStateUser } from '../../constants/initialUser'

const initialState = initialStateUser


export const createAccountDataSlice = createSlice({
  name: 'accountData',
  initialState,
  reducers: {
    setUserAcc: (state = initialState, action) => {
      state.userAcc = {
        ...state.userAcc,
        ...action.payload,
        platform: "browser"
      }
    },
    setItems(state = initialState, action) {
      state.userAcc.items = action.payload
    },
    setAccImg(state = initialState, action) {
      state.userAcc.img = action.payload
    },
    setAccPhone(state = initialState, action) {
      state.userAcc.phone = action.payload
    },
    setAccAddress(state = initialState, action) {
      state.userAcc.billAddress = action.payload
    },
    setAccIsLogined: (state = initialState, action) => {
      state.userAcc.isLogined = action.payload
    },
    setAccName: (state = initialState, action) => {
      state.userAcc.name = action.payload
    },
  },
})

export const {
  setUserAcc, 
  setItems,  
  setAccImg,
  setAccPhone,
  setAccAddress,
  setAccIsLogined,
  setAccName,
} = createAccountDataSlice.actions
export default createAccountDataSlice.reducer
