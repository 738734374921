import React, { useEffect, useState } from 'react'
import { Checkbox, Switch } from '@mui/material'

const FilterModalElement = ({item, setChange, change}) => {
  const [localProductsFilter, setLocalProductsFilter] = useState(localStorage.getItem('FilteredCatalog') ? JSON.parse(localStorage.getItem('FilteredCatalog')) : []);
  const [inFilter, setInFilter] = useState(localProductsFilter?.includes(item.sku))

  useEffect(() => {
    setLocalProductsFilter(JSON.parse(localStorage.getItem('FilteredCatalog')));
    console.log(JSON.parse(localStorage.getItem('FilteredCatalog')));
  }, [localStorage.getItem('FilteredCatalog')])
  useEffect(() => {
    setInFilter(localProductsFilter?.includes(item.sku));
  }, [localProductsFilter])
  // console.log(item);
  // console.log(inFilter);
  const handleChange = () => {
    setChange(!change)
    if (!inFilter) {
      let array = localStorage.getItem('FilteredCatalog') ? JSON.parse(localStorage.getItem('FilteredCatalog')) : []
      array.push(item.sku)
      console.log(array);
      localStorage.setItem('FilteredCatalog', JSON.stringify(array))
    } else {
      localStorage.setItem('FilteredCatalog', JSON.stringify(localProductsFilter?.filter((el) => el !== item.sku)));
    }
  }

  return (
    <div className='CategoryFilterModal__body__item' key={'CategoryFilterModal__body__item '+item.sku}>
      <div className='CategoryFilterModal__body__item__data'>
        <div className='CategoryFilterModal__body__item__imgBox'>
          <img src={item.img} alt="" />
        </div>
        <span>{item.name}</span>
      </div>
      <Checkbox
        className='previewProfile__right__paymentType__elem__row__check'
        checked={!inFilter}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
        onChange={() => {handleChange()}}
      />
    </div>
  )
}

export default FilterModalElement