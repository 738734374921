import { useSelector } from "react-redux"
import { mainApi } from "../store/services/mainApi"
import { useEffect, useState } from "react"

const useUpdateCart = () => {
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const allItemsCartSKUs = allItemsCart.map((el) => el.sku)
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const [updatedList, setUpdatedList] = useState()

  useEffect(() => {
    if (!isLoading) {
      const newArray = allProductsData.filter((el) => allItemsCartSKUs.includes(el.sku))
      const rr = newArray.map(newObj => {
        const oldObj = allItemsCart.find(obj => obj.sku === newObj.sku);
        if (oldObj && newObj) {
          return {...newObj,
            quantity: oldObj.quantity,
            quantityOrder: oldObj.quantityOrder,
            spoPrice: oldObj.spoPrice,
            marketPriceSDP: oldObj?.spoPrice ? oldObj.spoPrice : oldObj.marketPriceCP,
            marketPriceDP: oldObj?.spoPrice ? oldObj.spoPrice : oldObj.marketPriceCP,
            marketPriceCP: oldObj?.spoPrice ? oldObj.spoPrice : oldObj.marketPriceCP,
            marketPrice: oldObj?.spoPrice ? oldObj.spoPrice : oldObj.marketPriceCP,
            currentUnit: oldObj.currentUnit,
          }
        }
    });
    console.log(rr);
    }
  }, [allProductsData])

  return []
}

export default useUpdateCart