import React, { useEffect, useState } from 'react'
import './CategoriesPage.scss'
import { mainApi } from '../../../../store/services/mainApi'
import ContentCard from '../ContentCard/ContentCard'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useDispatch,useSelector } from 'react-redux'
import { setSearch } from '../../../../store/slices/search'
import { useLocation } from 'react-router'
import { setPriceIvaMode } from '../../../../store/slices/contextUI'
import FilterIcon from '../../assets/images/icon_filter.svg'
import { Switch } from '@mui/material'
import { setProMode } from '../../../../store/slices/contextUI'
import { setFilterCategory, setFilterSpecialization, setFiltersBrand, setFiltersBusinessType } from '../../../../store/slices/filterData'
import Slider from '@mui/material/Slider';
import LineCard from '../../Card/LineCard/LineCard'
import TableCard from '../../Card/TableCard/TableCard'


export default function CategoriesPage() {
  const location = useLocation()
  const dispatch = useDispatch()
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const { categoryName, categoryType, tradeName } = useParams()
  const proMode = useSelector((state) => state.contextUI.proMode)

  useEffect(() => {
    if (location.pathname === '/home') {
      dispatch(setSearch(''))
    }
  })

  const filters = useSelector((state) => state.filterData.filterData.filters)
  const [mapList, setMapList] = useState([])
  useEffect(() => {
    dispatch(setFilterCategory([categoryName]))
    dispatch(setFilterSpecialization([]))
  }, [])

  useEffect(() => {
    switch (categoryType) {
      case 'category':
        dispatch(setFilterSpecialization([]))
        dispatch(setFiltersBrand([]))
        dispatch(setFiltersBusinessType([]))
        dispatch(setFilterCategory([categoryName]))
        break;
      case 'businessType':
        dispatch(setFilterSpecialization([]))
        dispatch(setFiltersBrand([]))
        dispatch(setFilterCategory([]))
        dispatch(setFiltersBusinessType([categoryName]))
        break;
      case 'brand':
        dispatch(setFilterSpecialization([]))
        dispatch(setFiltersBusinessType([]))
        dispatch(setFilterCategory([]))
        dispatch(setFiltersBrand([categoryName]))
      break;
      default:
        break;
    }
  }, [categoryName, categoryType])
  
  function findValueInArray(arr, value) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === value) {
            return true; // Возвращаем индекс элемента равного заданному значению
        }
    }
    return false; // Если значение не найдено, возвращаем -1
  }


  
  useEffect(() => {
    if (!isLoading) {
      let filteredProducts = allProductsData
      ?.filter(product =>
        product.enabled == true 
        && (filters.category.length > 0 ? findValueInArray(filters.category,product.category) : true)
        && (filters.brand.length > 0 ? product.brand?.includes(filters.brand) : true) 
        && (filters.businessType.length > 0 ? product.businessType?.includes(filters.businessType) : true)
        && (filters.specialization.length > 0 ? product.specialization?.includes(filters.specialization) : true)
        && (filters.section.length > 0 ? product.section?.some((el) => filters.section.includes(el)) : true)
      )?.filter((el) => el?.sku !== 'BHieCILman');
      filteredProducts = filteredProducts.sort(function(a, b) {if (a.name < b.name) return -1})
        
      if (categoryType === 'businessType') {
      filteredProducts = filteredProducts.sort((a, b) => {
        const categoryNamesA = a?.topPerfil?.split(',');
        const categoryNamesB = b?.topPerfil?.split(',');

        const categoryNameInA = categoryNamesA?.includes(categoryName);
        const categoryNameInB = categoryNamesB?.includes(categoryName);
        if (categoryNameInA && !categoryNameInB) {
          return -1;
        }
        if (!categoryNameInA && categoryNameInB) {
          return 1;
        }
        return 0;
      })}
      
      setMapList(filteredProducts);
    }
  }, [filters, isLoading])

  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const [showMore, setShowMore] = useState(false)


  return (
    <div className='categoriesMain'>
      {/* <CategoryNews/> */}
      <div className='cartModal__header__proMod' style={{fontFamily: 'Montserrat'}}>
        Pro Mode (Sin Foto)
        <Slider
          step={1}
          min={1}
          max={3}
          value={proMode}
          onChange={(e) => dispatch(setProMode(e.target.value))}
          sx={{
            width: '80px',
            margin: '10px 20px',
            color: '#5FC56E',
          }}
        />
      </div>
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{categoryName}
        </div>
        {/* <img src={HeartBroken} alt="" width={50}/>
        <span>(Marcar como categoria favorita)</span> */}
      </div>
      {categoryName && (
        <div className='categoriesMain__title'>
          {/* <Typography variant='h2' component='h2' sx={{ fontSize: '20px', fontWeight: '600', fontFamily: 'Montserrat;', color: '#4C4C4C' }}>
            {categoryType.charAt(0).toUpperCase() + categoryType.slice(1) + ' - ' + categoryName}
          </Typography> */}
          {/* <div
            onClick={() => {dispatch(setUiFilterModal(true))}}
            className='categoriesMain__category__title__filter'><img src={FilterIcon} alt="" />Filtros</div>
          <FilterModal/> */}
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <span style={{color: '#A4A4A4', marginLeft: '83px', marginBottom: '-14px'}}>IVA</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <div style={{marginRight: '-6px'}}>
            <span style={{color: '#A4A4A4'}}>Los precios</span> sin
          </div>
          <Switch  
            className='ProfileMobile__status__switch'
            checked={ivaSwitch}
            onChange={() => {dispatch(setPriceIvaMode(!ivaSwitch))}}
            sx={{
              width: '68px',
              '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                  width: '66px'},
              '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#fff !important',
                  widows: '20px',
              },
              '& .Mui-checked+.MuiSwitch-track': {
                  backgroundColor: '#5FC56E !important'},
              '& .MuiSwitch-track': {

                      height: '24px',
                      width: '70px',
                      borderRadius: '14px',

                      transform: 'translateX(-5px) translateY(-5px)'
                  }
              }}
            />
            <div style={{marginLeft: '-16px'}}>
              сon
            </div>
        </div>
      </div>
      {<div className='categoriesPage'>
          {isLoading
          ? <CircularProgress/> :
          showMore ?  
          proMode === 2 ? mapList.map((item) => <ContentCard card={item}  key={item?.title+item?.sku} />) 
          : proMode === 3 ? mapList.map((item) => <TableCard item={item}  key={item?.title+item?.sku} />) 
          : mapList.map((item) => <LineCard item={item}  key={item?.title+item?.sku} />)
          : 
          proMode === 2 ? mapList.slice(0,10).map((item) => <ContentCard card={item}  key={item?.title+item?.sku} />) 
          : proMode === 3 ? mapList.slice(0,10).map((item) => <TableCard item={item}  key={item?.title+item?.sku} />) 
          : mapList.slice(0,10).map((item) => <LineCard item={item}  key={item?.title+item?.sku} />)
          }
      </div>}
      {!showMore && <div 
        className='tab__btn'
        onClick={() => {setShowMore(true)}}
        style={{width: '50%', margin: '0 auto', marginTop: '20px'}}
      >Ver todo</div>}
    </div>
  )
}
