import React from 'react'
import './StoreTab.scss'
import { useNavigate, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { setUiFavoriteModal } from '../../../store/slices/contextUI'

const StoreTab = () => {
  const tabParams = useParams().tab
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className='StoreTab'>
      <div className='StoreTab__title'>
        {tabParams}
      </div>
      <div className='StoreTab__content'>
        <div className='StoreTab__content__header'>
          <div 
            className='tab__btn'
            style={{width: '100%'}}
            onClick={() => {navigate('/home/category/'+tabParams) }}
          >
            Ver toda despensa
          </div>
        </div>
        <div className='StoreTab__content__body'>
          <div className='StoreTab__content__body__row'>
            {/* <div className='tab__btn'>
              New
            </div>
            <div className='tab__btn'>
              Sale
            </div> */}
          </div>
          <div className='StoreTab__content__body__row' style={{ justifyContent: 'center', top: '10%'}}>
            {/* <div 
              className='tab__btn'
              onClick={() => {dispatch(setUiFavoriteModal(true))}}
            >
              Favorits
            </div> */}
          </div>
        </div>
        <div className='StoreTab__content__footer'>
          {/* <div 
            className='tab__btn'
            onClick={() => {navigate('/home/brand/'+tabParams) }}
          >
            Brand
          </div>
          <div 
            className='tab__btn'
            onClick={() => {navigate('/home/businessType/'+tabParams) }}
          >
            Cuisine
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default StoreTab