import { createBrowserRouter, Navigate } from 'react-router-dom'
import React from 'react'
import CategoriesPage from './pages/components/Content/Categories/CategoriesPage'
import ComplitedPayCart from './pages/components/Orders/Complited/ComplitedPayCart'
import Header from './pages/components/Header/Header'
import SectionSlider from './pages/components/Content/PreviewCategories/PreviewSection/SectionSlider/SectionSlider'
import SearchPage from './pages/components/Content/SearchPage/SearchPage'
import PreviewFilter from './pages/components/Content/PreviewCategories/PreviewFilter/PreviewFilter'
import PreviewNav from './pages/components/Content/PreviewCategories/PreviewNav/PreviewNav'
import PreviewAll from './pages/components/Content/PreviewCategories/PreviewAll/PreviewAll'
import OrderPage from './pages/components/Account/Profile/MainSection/OrderPage/OrderPage'
import PreviewSection from './pages/components/Content/PreviewCategories/PreviewSection/PreviewSection'
import HeaderMobile from './pages/components/Header/HeaderMobile/HeaderMobile'
import PreviewNavMobile from './pages/components/Content/PreviewCategories/PreviewNav/PreviewNavMobile/PreviewNavMobile'
import ProfileMobile from './pages/components/Account/Profile/ProfileMobile/ProfileMobile'
import PreviewList from './pages/components/Content/PreviewCategories/PreviewList/PreviewList'
import FilterModal from './pages/components/ModalWindows/FilterModal/FilterModal'
import ItemPage from './pages/components/ItemPage/ItemPage'
import ProfileHome from './pages/components/Account/Profile/ProfileHome/ProfileHome'
import ProfileInfo from './pages/components/Account/Profile/ProfileInfo/ProfileInfo'
import ProfileOrders from './pages/components/Account/Profile/ProfileOrders/ProfileOrders'
import ProfileLanguages from './pages/components/Account/Profile/ProfileLanguages/ProfileLanguages'
import DatosPage from './pages/components/Account/Profile/ProfileMobile/DatosPage/DatosPage'
import OrderPageMobile from './pages/components/Account/Profile/ProfileMobile/OrderPageMobile/OrderPageMobile'
import NewOrderPage from './pages/components/NewOrderPage/NewOrderPage'
import SpecializationPage from './pages/SpecializationPage/SpecializationPage'
import SpecializationCategoryPage from './pages/SpecializationCategoryPage/SpecializationCategoryPage'
import ToTopBtn from './pages/components/Content/ToTopBtn/ToTopBtn'
import ShadowPage from './pages/ShadowPage/ShadowPage'
import HomePage from './pages/HomePage/HomePage'
import HomeTab from './pages/HomeTab/HomeTab'
import AllTab from './pages/AllTab/AllTab'
import StaticPage from './pages/ProfilePage/StaticPage/StaticPage'
import SalePage from './pages/SalePage/SalePage'
import { StaticOrder } from './pages/ProfilePage/StaticOrder/StaticOrder'
import { StaticOrderFP } from './pages/ProfilePage/StaticOrder/StaticOrderFP/StaticOrderFP';
import SaleNewOrderPage from './pages/SaleNewOrderPage/SaleNewOrderPage.jsx'


export const routerDeleverItems = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to={'/home'} />,
  },
  {
    path: '/home',
    element: 
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <HomePage />
      </div>
  },
  {
    path: '/home/:tab',
    element: 
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <HomeTab />
      </div>
  },
  {
    path: '/home/:mod/:tab',
    element: 
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <AllTab />
      </div>
  },
  {
    path: '/searchPage',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SearchPage />
      </div>,
  },
  {
    path: '/searchPage/:tradeName',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SearchPage />
      </div>,
  },
  {
    path: '/categories',
    element: 
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <PreviewList />
      </div>
  },
  {
    path: '/categories/:categoryType',
    element: 
      <div className='App__page'>
        <ToTopBtn />
        <FilterModal />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <PreviewAll />
      </div>
  },
  {
    path: '/categories/:categoryType/:categoryName',
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <CategoriesPage />
      </div>
  },
  {
    path: '/categories/:categoryType/:categoryName/:tradeName',
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <CategoriesPage />
      </div>
  },
  {
    path: '/specialization/:specializationName',
    element:
      <div className='App__page'>
        <ToTopBtn /> 
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SpecializationPage/>
      </div>
  },
  {
    path: '/specialization/:specializationName/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn /> 
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SpecializationPage/>
      </div>
  },
  {
    path: '/specialization/:specializationName/:categoryName',
    element:
      <div className='App__page'>
        <ToTopBtn /> 
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SpecializationCategoryPage/>
      </div>
  },
  {
    path: '/specialization/:specializationName/:categoryName/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn /> 
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SpecializationCategoryPage/>
      </div>
  },
  {
    path: '/profile/home',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header/>
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ProfileHome/>
        <HomePage/>
      </div>,
  },
  {
    path: '/profile/info',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header/>
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ProfileInfo/>
        <div className='DatosPageMobile'>
          <DatosPage/>
        </div>
      </div>,
  },
  {
    path: '/profile/orders',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header/>
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ProfileOrders/>
        <OrderPageMobile/>
      </div>,
  },
  {
    path: '/profile/languages',
    element: 
      <div className='App__container' style={{display: 'flex', flexDirection: 'column'}}>
        <ToTopBtn />
        <Header/>
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ProfileLanguages/>
        <ProfileMobile/>
      </div>,
  },
  {
    path: '/order/:orderId',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <OrderPage/>
      </div>
  },
  {
    path: '/item/:id',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ItemPage/>
      </div>
  },
  {
    path: '/newOrder',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <NewOrderPage/>
      </div>
  },
  {
    path: '/newOrder/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <NewOrderPage />
      </div>
  },
  {
    path: '/newOrder/:platform/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <NewOrderPage/>
      </div>
  },
  {
    path: '/orderComplited',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ComplitedPayCart/>
      </div>
  },
  {
    path: '/orderComplited/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ComplitedPayCart/>
      </div>
  },
  {
    path: '/orderComplited/:platform/:tradeName',
    element:
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ComplitedPayCart/>
      </div>
  },
  {
    path: '/category/:tradeName', 
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ShadowPage/>
      </div>
  },
  {
    path: '/category/:platform/:tradeName', 
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <ShadowPage/>
      </div>
  },
  {
    path: '/sale/:saleId', 
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SalePage/>
      </div>
  },
  {
    path: '/sale/account/:saleId', 
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SalePage/>
      </div>
  },
  {
    path: '/sale/newOrder/:saleId', 
    element:
      <div className='App__page'> 
        <ToTopBtn />
        <Header />
        <HeaderMobile/>
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile/>
        <SalePage/>
      </div>
  },
  {
    path: '/account/:tradeName', 
    element:
      <StaticPage/>
  },
  {
    path: '/account/facturas/:tradeName', 
    element:
      <StaticPage/>
  },
  {
    path: '/account/order/:id', 
    element:
      // <StaticOrder/>
      <StaticOrderFP />
  },
  {
    path: '/client/static/order/:id', 
    element:
      <StaticOrderFP />
  }
  
])