import React from 'react'
import './HeaderMobile.scss'
import LoginButton from '../Login/LoginButton'
import LogoTPO from '../../assets/images/TPOLogoNew.svg'
import LoginModal from '../../ModalWindows/LoginModal/LoginModal'
import RegisterModal from '../../ModalWindows/RegisterModal/RegisterModal'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersSection } from '../../../../store/slices/filterData'
import { useHref, useNavigate, useParams } from 'react-router'

const HeaderMobile = () => {
  const openModal = useSelector(state => state.accModal.accModal.Modal)
  const dispatch = useDispatch()
  const tradeNameParams = useParams().tradeName
  const navigate = useNavigate()

  return (
    <div className='HeaderMobile'>
			<div className='HeaderMobile__logo' 
      onClick={() => {if (tradeNameParams !== undefined) {if (location.pathname?.split('/')[1] === 'category') {navigate('/home'); location.reload()} else {navigate(`/category/${tradeNameParams}`)}} else {navigate('/home'); location.reload()}}}
      >
          <img src={LogoTPO} alt='' width={22}/>
          <span>Tu Producto Online</span>
        </div>
			<LoginButton/>
      {openModal === 'Login' && <LoginModal/>}
      {openModal === 'Register' && <RegisterModal/>}
		</div>
  )
}

export default HeaderMobile