import React from 'react'
import './ImageModal.scss'

const ImageModal = ({ img, setClose }) => {
  return (
    <div className='ImageModal' onClick={() => setClose(false)}>
      <img src={img} alt="" />
      <div className='ImageModal__close' onClick={() => setClose(false)}>Hide image</div>
    </div>
  )
}

export default ImageModal