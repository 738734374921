import React, { useEffect } from 'react'
import './HomePage.scss'
import BarBtn from '../components/assets/images/BarBtnEs.svg'
import AllCategoryBtn from '../components/assets/images/AllCategoryBtnEs.svg'
import ChiefBtn from '../components/assets/images/ChifeBtnEs.svg'
import StoreBtn from '../components/assets/images/StoreBtn.svg'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { setClearFilters, setFiltersSection } from '../../store/slices/filterData'

const HomePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setClearFilters())
  }, [])

  return (
    <div className='HomePage'>
      <div 
        onClick={() => {navigate('/home/Bar'); dispatch(setFiltersSection(['Bar']))}}
      >
        <img src={BarBtn} alt="" />
      </div>
      <div 
        onClick={() => {navigate('/categories/category')}}
      >
        <img src={AllCategoryBtn} alt="" />
      </div>
      <div 
        onClick={() => {navigate('/home/Kitchen'); dispatch(setFiltersSection(['Kitchen']))}}
      >
        <img src={ChiefBtn} alt="" />
      </div>
      {/* <div 
        onClick={() => {navigate('/home/Store'); dispatch(setFiltersSection(['Store']))}}
      >
        <img src={StoreBtn} alt="" />
      </div> */}
    </div>
  )
}

export default HomePage