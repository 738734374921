import React from 'react'
import './OrderPage.scss'
import ProfileSideBar from '../../SideBar/ProfileSideBar'
import DarkArrowLeft from '../../../../assets/images/DarkArrowLeft.svg'
import { useNavigate } from 'react-router'

const OrderPage = () => {
  const navigate = useNavigate()

  const listItem = 
  <tr>
    <td><img src="https://i.imgur.com/xmzZVFj.jpg" alt="" width={75} height={75}/></td>
    <td>
      Zumos Frescos Limon TPO 1,1lt
      <span>AZFLe1_1lt</span>
    </td>
    <td>Und</td>
    <td>10</td>
    <td>10%</td>
    <td>8.00€</td>
    <td>80.00€</td>
  </tr>

  return (
    <div className='OrderPage'>
      <div 
        className='OrderPage__back'
        onClick={() => navigate('/Profile')}
      ><img src={DarkArrowLeft} alt="" /></div>
      <div className='OrderPage__total'>
        <div className='OrderPage__total__sideBar'>
          <ProfileSideBar/>
        </div>
        <div className='OrderPage__total__content'>
          <div className='OrderPage__total__content__info'>
            <div className='OrderPage__total__content__info__head'>
              <div className='OrderPage__total__content__info__head__block'>
                <p>Creation: <span>22.12.2023</span></p>
                <span>11.04</span>
              </div>
              <div className='OrderPage__total__content__info__head__block'>
                <p>Delivery: <span>cant find</span></p>
                <span>in order</span>
              </div>
              <div className='OrderPage__total__content__info__head__block'>
                <p>total Items: <span>24</span></p>
              </div>
            </div>
            <div className='OrderPage__total__content__info__body'>
              <div className='OrderPage__total__content__info__body__head'>
                Comment:
              </div>
              <div className='OrderPage__total__content__info__body__comment'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus commodi saepe assumenda, architecto ipsam dolores exercitationem fugiat nisi consectetur deserunt perspiciatis quos reiciendis numquam facere sit fuga, amet quis ea?
              </div>
            </div>
          </div>
          <div className='OrderPage__total__content__totalPrice'>
            <div className='OrderPage__total__content__totalPrice__total'>
              <div className='OrderPage__total__content__totalPrice__total__prices'>
                <div>
                  Products
                  <span> 404.65€</span>
                </div>
                <div>
                  IVA
                  <span>56.98€</span>
                </div>
                <div>
                  Delivery
                  <span>30.00€</span>
                </div>
                <div>
                  <p>Total</p>
                  <span><p>434.65€</p></span>
                </div>
              </div>
              <div className='OrderPage__total__content__totalPrice__total__paid'>
                <div>
                  Paid?
                  <span>434.65€</span>
                </div>
              </div>
            </div>
            <div className='OrderPage__total__content__totalPrice__btnBox'>
              <div className='OrderPage__total__content__totalPrice__btnBox__btn'>
              </div>
              <div className='OrderPage__total__content__totalPrice__btnBox__btn'>
              </div>
              <div className='OrderPage__total__content__totalPrice__btnBox__btn'>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='OrderPage__products'>
        <div className='OrderPage__products__btnBox'>
          <div className='OrderPage__products__btnBox__btn'></div>
          <div className='OrderPage__products__btnBox__btn'></div>
          <div className='OrderPage__products__btnBox__btn'></div>
          <div className='OrderPage__products__btnBox__btn'></div>
          <div className='OrderPage__products__btnBox__btn'></div>
        </div>
        <div className='OrderPage__products__table'>
          <div className='OrderPage__products__table__head'>
            <table>
              <tr>
                <td>Image</td>
                <td>Nambre</td>
                <td>Unit</td>
                <td>Cantidad</td>
                <td>IVA</td>
                <td>Price</td>
                <td>Total</td>
              </tr>
            </table>
          </div>
          <div className='OrderPage__products__table__body'>
            <table>
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
              {listItem}
            </table>
          </div>
        </div>
      </div>  
    </div>
  )
}

export default OrderPage