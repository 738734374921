import React, { useEffect } from 'react'
import './Dashboard.scss'
import { Box } from '@mui/material'
import { MainInfo } from './MainInfo/MainInfo'
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo'
import { ProductsInfo } from './ProductsInfo/ProductsInfo'
import { OrdersInfo } from './OrdersInfo/OrdersInfo'
import { useMediaQuery } from '@mui/material'

import { ReactComponent as Watsup } from '../../../components/assets/images/WhatsupIconLogin.svg'
import { ReactComponent as Telegram } from '../../../components/assets/images/telegramStatic.svg'
import { useTranslation } from 'react-i18next'

export const Dashboard = ({ contact, orders, scrollList, tableContainerRef }) => {
  const mobileView = useMediaQuery('(max-width: 798px)')
  const { t } = useTranslation()
  return (
    <div className='staticDashboard'>
      {/* <h1>Dashboard</h1> */}
      <Box sx={{ width: mobileView ? '100%' : '60%' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: mobileView ? 'column' : 'row', gap: '20px' }}>
          <MainInfo contact={contact} />
          {mobileView && (
            <Box className='mainInfo__content__right__text__item'>
              <p className='mainInfo__content__right__text__item__title'>{t('StaticPage.mainInfo.contactUs')}</p>
              <Watsup
                width={50}
                height={50}
                className='mainInfo__content__right__text__item__image'
                onClick={() => window.open('https://wa.me/34642868541', '_blank')}
              />
              <Telegram
                width={50}
                height={50}
                className='mainInfo__content__right__text__item__image'
                onClick={() => window.open('https://t.me/TPOBCN', '_blank')}
              />
            </Box>
          )}

          <AdditionalInfo contact={contact} />
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <OrdersInfo orders={orders} srollList={scrollList} tableContainerRef={tableContainerRef}/>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: mobileView ? '100%' : '40%' }}>
        <ProductsInfo orders={orders} />
      </Box>
    </div>
  )
}
