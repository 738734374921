import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className='footer__main'>
        <div className='footer__about'>
          <div className='footer__about__btn'>About company</div>
          <div className='footer__about__btn'>Contacts</div>
          <div className='footer__about__btn'>How to order</div>
          <div className='footer__about__btn'>Delivery & payment</div>
          <div className='footer__about__btn'>News</div>
        </div>
      </div>
      <div className='footer__white__fake'></div>
    </footer>
  )
}

export default Footer
