import React, { useState, useEffect, useMemo } from 'react'
import './RegisterModal.scss'
import { setAccModal } from '../../../../store/slices/accModal';
import closeIcon from './../../assets/images/closeElem.svg';
import axios from 'axios';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { setAccName, setAccPhone, setUserAcc } from '../../../../store/slices/accountData';
import { setIsLogin } from '../../../../store/slices/contextUI';
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'; 
import Snackbar from '@mui/material/Snackbar';
import { Modal } from '@mui/material';

const RegisterModal = () => { 
    const dispatch = useDispatch()
    const [phone, setPhone] = useState()
    const [warn, setWarn] = useState(false)
    const handleGoToHome = () => {
        dispatch(setAccModal(''))
    }

    const handleRegister = async () => {
        setCoolDown(true)
        let informData =  {
            phone: registerData.number,
            password: registerData.password,
        };
        const responseLogin = await axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
        dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
        
        if (responseLogin.data === '') {
            const informData =  {
                billAddress: {address: '', city: '', postalCode: ''},
                cif: '',
                code: '',
                difficilidad: false,
                employee: '',
                enabled: 1,
                extraPedidos: false,
                group: '',
                iban: '',
                img: '',
                name: registerData.name,
                nif: '',
                phone: registerData.number,
                platform: 'tpoAppCom',
                rate: 0,
                responsable: '',
                solvencia: '',
                swift: '',
                tags: '',
                time: 0,
                tipo: '',
                tradeName: '',
                type: 'userUnion',
                username: '',
                password: registerData.password,
            }
            console.log('send')
            await axios.post('https://tpomobi.shop/createContactAdmin', {data: informData}).then(async (response) => {
                console.log(response)
                dispatch(setIsLogin(response.data.acknowledged))
                let informData =  {
                    phone: registerData.number,
                    password: registerData.password,
                };
                const responseLogin = await axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
                // console.log(responseLogin.data)
                dispatch(setUserAcc(responseLogin.data))
                dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
                const targetDate = new Date();
                targetDate.setDate(targetDate.getDate() + 30); 
                localStorage.setItem('LoginRules', JSON.stringify({dateLogin: new Date(), refreshLogin: targetDate}))
                localStorage.setItem('LoginData', JSON.stringify({phoneLogin: registerData.number, passwordLogin: registerData.password}))
                responseLogin.data !== '' && setWarn(true)
                responseLogin.data !== '' && setTimeout(() => {
                    dispatch(setAccModal(''))
                }, 1500);
            })
            console.log('get');}
            else {
            handleClick({ vertical: 'top', horizontal: 'center' })
          }

        setCoolDown(false)
    }

    const [stateSnake, setStateSnake] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    const { vertical, horizontal, open } = stateSnake;

    const handleClick = (newState) => {
      setStateSnake({ ...newState, open: true });
    };

    const handleClose = () => {
      setStateSnake({ ...stateSnake, open: false });
    };

    const [registerData, setRegisterData] = useState({
        name: '',
        number: '',
        password: '',
        repPassword: '',
    })
    useEffect(() => {
        console.log(registerData)
    }, [registerData])
    
    const handleSetRegisterData = (event) => {
        setRegisterData({ ...registerData, [event.target.name]: event.target.value })
    }
    const [coolDown, setCoolDown] = useState(false)

    return (
        <div className='RegisterModal'>
            <div className='RegisterModal__background' onClick={handleGoToHome}></div>
            <div className='RegisterModal__backgroundModal'>
                <div className="RegisterModal__header">
                    <div className='RegisterModal__header__title'>Sign Up</div>
                    <div onClick={handleGoToHome}>
                        <img src={closeIcon} alt="X" width={20} height={20} style={{cursor: 'pointer'}}/>
                    </div>
                </div>
                <form action="POST" className='RegisterModal__form'>
                    <Box sx={{ width: 500 }}>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={open}
                            onClose={handleClose}
                            message="User already register"
                            key={vertical + horizontal}
                        />
                    </Box>
                    <label className='label' htmlFor="regnamePlaceholder">Nombre</label>
                    <input
                        name='name'
                        type="text"
                        id='regnamePlaceholder'
                        onChange={(e) => handleSetRegisterData(e)}
                        className='RegisterModal__form__input'
                    />
                    <label className='label' htmlFor="regnumberPlacholder">Numero de móvil</label>
                    <PhoneInput
                        name='number'
                        className='RegisterModal__form__phoneInput'
                        defaultCountry="es"
                        value={phone}
                        onChange={(phone) => {
                            setPhone(phone)
                            setRegisterData({ ...registerData, number: phone.replace('+', '') })
                        }}
                    />
                    <label className='label' htmlFor="regpasswordPlaceholder">Contraseña</label>
                    <input
                        name='password'
                        type='password'
                        id='regpasswordPlaceholder'
                        onChange={(e) => handleSetRegisterData(e)}
                        className='RegisterModal__form__input'
                    />
                    <label
                        className='label'
                        htmlFor="regrepPasswordPlaceholder"
                        style={{ color: registerData.password === registerData.repPassword ? 'green' : 'red' }}
                    >Contraseña</label>
                    <input
                        name='repPassword'
                        type='password'
                        id='regrepPasswordPlaceholder'
                        onChange={(e) => handleSetRegisterData(e)}
                        className='RegisterModal__form__input'
                    />
                    <div className='RegisterModal__button'>
                        <div
                            style={{opacity:registerData.password !== registerData.repPassword && '.5' }}
                            className='RegisterModal__button__login__active'
                            onClick={() => !coolDown && registerData.password === registerData.repPassword && registerData.number !== '' && registerData.name !== '' ? handleRegister() : alert('Complete all fields')}
                        >
                            Registrarse
                        </div>
                    </div>
                </form>

            </div>
            <Modal open={warn} onClose={() => setWarn(false)}>
                <Box 
                className='NewOrderPage__mainEditOrder__sendOrder__modal'
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    outline: 'none',
                    border: '3px solid #5FC56E',
                    fontFamily: 'Montserrat',
                    fontSize: '17px',
                    color: '#5FC56E',
                    borderRadius: 6,
                    gap: 2,
                    p: 4,
                }}>
                    <b style={{marginBottom: '10px'}}>Great! 👍 <br/>
                    Your account was created</b>
                    
                </Box> 
            </Modal>
        </div>
)}

export default RegisterModal
