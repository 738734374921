import React, { useState, useEffect} from 'react'
import './DatosPage.scss'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setAccImg } from '../../../../../../store/slices/accountData';
import SuccessModal from '../../../../assets/images/SuccessModal.svg'
import "react-international-phone/style.css";
import axios from 'axios';
import DarkArrowLeft from '../../../../assets/images/DarkArrowLeft.svg'
import { useNavigate } from 'react-router';



const DatosPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userAcc = useSelector((state) => state.accountData.userAcc);
	const [phone, setPhone] = useState('+'+userAcc?.phone);
	const [DifAddress, setDifAddress] = useState([])
	const [address, setAddress] = useState(userAcc?.billAddress?.address)
  const [successModal, setSuccessModal] = useState(false)
	const [linkImage, setLinkImage] = useState('')
	// console.log(responseUpdate);

	const handleSaveProfileData = async () => {
		const data = {
			...userAcc,
			name: editDataProfile.name,
			username: editDataProfile.username,
			tradeName: editDataProfile.tradeName,
			phone: editDataProfile.phone.replace('+', ''),
			img: editDataProfile.img,
			billAddress:editDataProfile.billAddress,
			id: userAcc._id,
			instagram: editDataProfile.instagram,
		}

		// const data = {
		// 	"id": "657b369892bb3ae66ae36a05",
		// 	"_id": "657b369892bb3ae66ae36a05",
		// 	"billAddress": {
		// "address": "Kamenets",
		// 		"city": "Boston"
		// 	},
		// 	"cif": [],
		// 	"code": "",
		// 	"dificilidad": false,
		// 	"email": "bratanarik@gmail.com",
		// 	"employee": "",
		// 	"enabled": false,
		// 	"extraPedidos": false,
		// 	"group": "",
		// 	"iban": [],
		// 	"img": "https://sun9-36.userapi.com/impg/LsRt0rNmUchrwkuNj7xFWeo8yTLZEZOXpMKUIA/WmBwpXMNWFo.jpg?size=1844x2160&quality=95&sign=bc4564f36dc2ea05b3fa6c918d6b8b94&type=album",
		// 	"name": "Test KIRILLL",
		// 	"nif": "",
		// 	"phone": "375297822011",
		// 	"rate": 0,
		// 	"responsable": "",
		// 	"solvencia": false,
		// 	"swift": [],
		// 	"tags": "",
		// 	"time": 0,
		// 	"tipo": "",
		// 	"tradeName": "BOT",
		// 	"type": "client",
		// 	"username": "TEStUserName",
		// 	"platform": null,
		// 	"instagram": "https://www.instagram.com/bolerobar_bcn"
		// };
		delete data._id
		const updateUser = await axios.post('https://tpomobi.shop/updateContactAdmin', {data}).then(() => {setTimeout(() => {
			setSuccessModal(false)
			location.reload();
		}, 1000)})
		console.log(updateUser);
		setSuccessModal(true);
	}
	React.useEffect(() => {
		setDifAddress(address.split(','))
		handleAndressInput()
	}, [address])

	const handleChangeLinkImage = () => {
		const inputLinkImage = prompt('Ingresa el link de la imagen')
		if (inputLinkImage !== '') {
			dispatch(setAccImg(inputLinkImage))
			setEditDataProfile(prevState => ({
				...prevState,
				img: inputLinkImage
			}))
		}
	}

	const handleAndressInput = () => {
		setEditDataProfile(prevState => ({
			...prevState,
			billAddress: {
				address: address || DifAddress[0] || '',
				city: DifAddress[1] || '',
				postalCode: DifAddress[2] || '',
			}
		}))
	}

	const [editDataProfile, setEditDataProfile] = useState({
		name: userAcc.name,
		username: userAcc.username,
		tradeName: userAcc.tradeName,
		phone: userAcc.phone,
		img: userAcc.img,
		billAddress: userAcc.billAddress,
		instagram: userAcc.instagram,
	})

	useEffect(() => {
		setEditDataProfile({
			name: userAcc.name,
			username: userAcc.username,
			tradeName: userAcc.tradeName,
			phone: userAcc.phone,
			img: userAcc.img,
			billAddress: userAcc.billAddress,
			instagram: userAcc.instagram,
		})
	}, [userAcc])

	const handleEditInput = (e) => {
		setEditDataProfile(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	}


  return (
		<div className='ProfileMobileDatos'>
			<div
				className='ProfileMobileDatos__back'
				onClick={() => navigate('/profile/home')}
			><img src={DarkArrowLeft} alt="" /></div>
			<div className='ProfileMobileDatos__section'>
				<div className="Profile__mainPage__section__mainSection__item__profile">
					<div className="Profile__mainPage__section__mainSection__item__profile__background">
						<div className="Profile__mainPage__section__mainSection__item__profile__background__img">
								<img src={userAcc.img} alt="" />
								<span onClick={() => {handleChangeLinkImage()}}>Cambiar foto</span>
						</div>
					<div className="Profile__mainPage__section__mainSection__item__profile__background__info">
						<div className="ProfilePlate__name">
								{userAcc.name}
						</div>
						<div className="ProfilePlate__username">
								User name:  <span>{userAcc.username}</span>
						</div>
						{userAcc.ambassador && 
						<div className="ProfilePlate__ambassador">
								Ambassador
						</div>}
						<div className="ProfilePlate__id">
								ID: <span>{userAcc._id}</span>
						</div>
						</div>
					</div>
					<form action="Post" className="Profile__mainPage__section__mainSection__item__profile__form">
						<label htmlFor="Nombre">Nombre</label>
						<input
							type="text" 
							name="name" 	
							id="Nombre"
							value={editDataProfile.name}
							onChange={(e) => {handleEditInput(e)}}
						/>
						<label htmlFor="Username">UserName</label>
						<input
							type="text" 
							name="username" 	
							id="Username"
							value={editDataProfile.username}
							onChange={(e) => {handleEditInput(e)}}
						/>
						<label htmlFor="tradeName">Nombre comercial</label>
						<input
							disabled
							type="text" 
							name="tradeName" 	
							id="tradeName"
							value={editDataProfile.tradeName}
							onChange={(e) => {handleEditInput(e)}}
						/>
						<label htmlFor="Number">Numero de movil</label>
						<input
							type="text" 
							name="phone" 	
							id="Number" 
							value={'+'+editDataProfile.phone}
							onChange={(e) => {handleEditInput(e)}}
						/>
						<label htmlFor="Direccion">Direccion</label>
						<input 
							type="text" 
							name="Adress" 
							id="Direccion"
							value={address}
							onChange={(e) => {
								setAddress(e.target.value)
								setDifAddress(e.target.value.split(','))
							}}/>
						<label htmlFor="Insta">Instagram Link</label>
						<input
							type="text" 
							name="instagram" 	
							id="Insta"
							value={editDataProfile.instagram}
							onChange={(e) => {handleEditInput(e)}}
						/>
					</form>
					<div className="Profile__mainPage__section__mainSection__item__profile__save">
							<div
									className="Profile__mainPage__section__mainSection__item__profile__save__btn"
									onClick={() => {handleSaveProfileData()}}
							>
									Guardar  
							</div>
							{successModal &&
							<div className="Profile__mainPage__section__mainSection__item__profile__save__success">
									<img
											src={SuccessModal}
											className="Profile__mainPage__section__mainSection__item__profile__save__success__img"
									/>
							</div>}
					</div>   
				</div>
			</div>
		</div>
  )
}

export default DatosPage