import { useState } from "react";

const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const orderDataConst = {
  address: "",
  date: Date.now(),
  deliveryDate: formatDate,
  deliveryPrice: 0,
  description: "",
  items: [],
  order: "CP",
  paidValue: 0,
  realType: "",
  status: {},
  tradeName: "",
  type: "browser",
  userAcc: {}
}

export default orderDataConst