import React from 'react'
import './ProfileInfo.scss'
import ProfileSideBar from '../SideBar/ProfileSideBar';
import DatosPage from '../ProfileMobile/DatosPage/DatosPage';

const ProfileInfo = () => {
  return (
    <div className='ProfileInfo__container'>
      <div className='ProfileInfo__container__section'>
        <div className='ProfileInfo__container__section__item__left'>
          <ProfileSideBar/>
        </div>
        <div className='ProfileInfo__container__section__item__right'>
          <DatosPage/>
        </div>
      </div>
    </div>
  )
}

export default ProfileInfo