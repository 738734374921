import React from 'react'
import './ProfileOrders.scss'
import ProfileSideBar from '../SideBar/ProfileSideBar';
import OrdersPage from '../ProfileMobile/OrdersPage/OrdersPage';

const ProfileOrders = () => {
  return (
    <div className='ProfileOrders__container'>
      <div className='ProfileOrders__container__section'>
        <div className='ProfileOrders__container__section__item__left'>
          <ProfileSideBar/>
        </div>
        <div className='ProfileOrders__container__section__item__right'>
          <OrdersPage/>
        </div>
      </div>
    </div>
  )
}

export default ProfileOrders