import React, { useState } from 'react'
import './PreviewNav.scss'
import { useDispatch, useSelector } from 'react-redux'
import buttonsCategories from '../../../../../constants/categoryButtons'
import { setCategoryButtonsTab } from '../../../../../store/slices/contextUI'
import { useNavigate } from 'react-router'
import PreviewMenu from './PreviewMenu/PreviewMenu'
import { setFiltersSection } from '../../../../../store/slices/filterData'


const PreviewNav = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const categoryButtonsTab = useSelector(state => state.contextUI.categoryButtonsTab.active)

    return (
        <div className='previewNav'>
            {buttonsCategories.map((item) => (
                <div 
                    key={item.name+item.icon}
                    className='previewNav__button'
                    onClick={() => {
                        dispatch(setCategoryButtonsTab(item));
                        dispatch(setFiltersSection([item.name]))
                        navigate('/home/'+item.tab)
                    }}
                    style={{ borderBottom: categoryButtonsTab.name === item.name ? '3px solid #5FC56E' : 'transparent' }}
                >
                    {item.name}
                    <img src={item.icon} alt={item.name} />    
                </div>
            ))}
            <PreviewMenu/>
        </div>
)}

export default PreviewNav
