import React, { useId, useState } from 'react'
import ItemCounterModal from '../../ModalWindows/ItemCardModal/ItemCounterModal/ItemCounterModal'
import { useSelector } from 'react-redux'
import unitEqual from '../../../../constants/unitEqual'
import { Checkbox } from '@mui/material'
import './LineCard.scss'
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit'
import useFindInCart from '../../../../hooks/useFindInCart'

const LineCard = ({ item }) => {
  const inCart = useFindInCart(item)
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const [grammMode, setGramMode] = useState(false)

  return (
    <>
      <div className='contentCardPro' key={item?.title+item?.sku} style={{textAlign: 'start'}}>
        {item?.name}
        <div 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', width: 'fit' }}
        >
          <ItemCounterModal item={item} keyId={item?.sku+item?.name+useId()} grammMode={grammMode}/>
          {item?.spoPrice ? <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
          <span style={{textDecoration: 'line-through'}}>
            {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span>
          <span style={{color: '#5FC56E'}}>
            {ivaSwitch ? (item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span> </div> :
          <span>
            {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span>
          }
        </div>
      </div> 
      <div 
        className='cartModal__body__item__row'
        style={{marginTop: '-20px', justifyContent: 'space-between', gap: '10px', width: ''}}
      >
        {!!item?.decimal && <div className='LineCard__div'>
          <span className='LineCard__div__text'>Pedir en gramos</span>
          <Checkbox
            className='previewProfile__right__paymentType__elem__row__check'
            checked={grammMode}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
            onChange={() => {setGramMode(!grammMode)}}
          />  
        </div>}
        <div className='LineCard__aprox'>
          {unitEqual.find((el) => el.sku === item?.sku)?.und && ('und: '+unitEqual.find((el) => el.sku === item?.sku)?.und+" kg aprox ")
          }
          {unitEqual.find((el) => el.sku === item?.sku)?.caja && ('caja: '+unitEqual.find((el) => el.sku === item?.sku)?.caja+" kg aprox ")
          }
          {unitEqual.find((el) => el.sku === item?.sku)?.saco && ('saco: '+unitEqual.find((el) => el.sku === item?.sku)?.saco+" kg aprox ")
          }
        </div>
      </div>
    </>
  )
}

export default LineCard