import React from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { routerDeleverItems } from './router'
import 'normalize.css'
import AutoLogin from './pages/components/ModalWindows/LoginModal/AutoLogin/AutoLogin'
import CartElemInfoModal from './pages/components/ModalWindows/CartElemInfoModal/CartElemInfoModal'
import Footer from "./pages/components/Footer/Footer";
import { useSelector } from 'react-redux'
import FavoriteModal from './pages/components/ModalWindows/FavoriteModal/FavoriteModal'
import AutoDataSynchronaizer from './pages/components/AutoDataSynchronaizer/AutoDataSynchronaizer'
import ItemCardModal from './pages/components/ModalWindows/ItemCardModal/ItemCardModal'


const App = () => {
  const openFavoriteModal = useSelector(state => state.contextUI.uiModal.favoriteModal)
  const ItemModalData = useSelector(state => state.contextUI.uiModal.itemModal)

  return (
    <div className='App'>
      <div className='App__padding'>
        <RouterProvider router={routerDeleverItems} />
        
        {openFavoriteModal && <FavoriteModal/>}
        <CartElemInfoModal/>
        

        <AutoLogin/>
        <AutoDataSynchronaizer/>
        {ItemModalData.isShow ? <ItemCardModal card={ItemModalData.item}/> : <></>}
      </div>
        <Footer/>
    </div>
  )
}

export default App