import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { get } from 'lodash'


export const salePageApi = createApi({
  reducerPath: 'salePageApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://tpomobi.shop' }),
  keepUnusedDataFor: 36,
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    //GET QUERIES
    getAllSalesOrders: builder.query({
      query: () => ({
        url: '/salesPageOrderGet',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: [ { type: 'Orders', id: 'LIST' } ],
      keepUnusedDataFor: 5000,
      pollInterval: 1000,
    }),
    getSalesOrderById: builder.query({
      query: (id) => ({
        url: `/salesPageOrderGet/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        providesTags: [ { type: 'Orders', id: 'LIST' } ],
        pollInterval: 1000,
      }),
    }),
    //UPDATE QUERIES
    updateSalesOrderById: builder.mutation({
      query: (data) => ({
        url: `/salesPageOrderUpdate/${data.id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: data.body,
        invalidatesTags: ['Orders'],
      }),
    }),
    //DELETE QUERIES
    deleteSalesOrderById: builder.query({
      query: (id) => ({
        url: `/salesPageOrderDelete/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        invalidatesTags: ['Orders'],
      }),
    }),
    
    //POST QUERIES
    createSalesOrder: builder.mutation({
      query: (body) =>  ({
        url: '/salesPageOrderCreate',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        invalidatesTags: ['Orders'],
        body
      })
    }),
  }),
})

