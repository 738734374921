import React, { useCallback, useEffect, useState } from 'react'
import { mainApi } from '../store/services/mainApi'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'


const useSendLog = (actionProp, detailsProp) => {
  
  const userData = useSelector((state) => state.accountData.userAcc)
  const localStoragePhone = JSON.parse(localStorage.getItem('LoginData'))?.phoneLogin ? JSON.parse(localStorage.getItem('LoginData'))?.phoneLogin : null
  const tradeNameParams = useParams().tradeName

  const sendLogs = async () => {
    try {
      let detailsUse = ''
      switch (actionProp) {
        case 'Change view of cards in personal link':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Change view of cards in final order page link':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              newOrder: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Click on alphabet navigation':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Click on filter items modal links':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Click on cart in personal links':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Click on cart':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              any: 'click was on any page, not personal links'
            },
          }
          break;
        case 'Click on mis pedido button':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'empty tradeName'
            },
          }
          break;
        case 'Click on telegram contact in header':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              any: 'click was on any page, not personal links'
            },
          }
          break;
        case 'Click on whatsup contact in header':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              any: 'click was on any page, not personal links'
            },
          }
          break;
        case 'User didn\'t complited payment':
          detailsUse = {
            ...detailsProp,
            user: userData ? {
              name: userData.name,
              phoneNumber: userData.phone,
              id: userData._id 
            } : localStorage.getItem('savedPhone') ? {
              phone: localStorage.getItem('savedPhone'),
              name: localStorage.getItem('savedName') ? localStorage.getItem('savedName') : 'empty name',
            } : 'empty user',
            page: {
              personalLinks: tradeNameParams ? tradeNameParams : 'click was on any page, not personal links'
            },
          }
          break;
        default: detailsUse = 'empty log details' 
          break;
      }

      const log = {
        timestamp: new Date().toISOString(),
        action: actionProp ? 'TPO.com '+actionProp : 'TPO.com empty log action',
        details: detailsUse,
      }

      await fetch('https://tpomobi.shop/addLog', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(log),
      })
      // console.log(log);
    } catch (error) {
      console.error('Failed to send log', error);
    }
  }

  return sendLogs
}

export default useSendLog