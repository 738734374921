import React, { useEffect } from 'react';
import './ProfileSideBar.scss';
import SignOutIcon from '../../../assets/images/SideBar-SigOut.svg';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setAccPhone, setAccName, setAccIsLogined, setUserAcc } from '../../../../../store/slices/accountData';
import ArrowDown from '../../../assets/images/ArrowDown_Black.svg'
import FacturacionIcon from '../../../assets/images/Facturacion.svg'
import sideBarTabs from '../../../../../constants/sideBarTabs';
import { setIsLogin, setProfileTab } from '../../../../../store/slices/contextUI';
import { initialStateUser } from '../../../../../constants/initialUser';

const ProfileSideBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isAmbassador = useSelector((state) => state.contextUI.profilePage.ambassador);
    
    const handleLogout = () => {
        localStorage.removeItem('LoginData')
        localStorage.removeItem('LoginRules')
        dispatch(setAccPhone(''))
        dispatch(setUserAcc(initialStateUser.userAcc))
        dispatch(setAccName(''))
        dispatch(setIsLogin(false))

        navigate('/home')
    }

    const userData = useSelector((state) => state.accountData.userAcc)
    const [id, setId] = React.useState('')
    const isLogin = useSelector((state) => state.contextUI.isLogin)
    useEffect(() => {
        if (isLogin) {
            setId(userData._id)
        } else {
            setId(localStorage.getItem('savedPhone')?.replace('+', ''))
        }
    }, [userData])

    return (
        <div className='ProfileSideBar__container'>
            <div className='ProfileSideBar__container__blockBtn'>
                {sideBarTabs.map((item, index) => (
                    !isAmbassador && <div
                        key={item.name+index+item.name} 
                        className='ProfileSideBar__container__blockBtn__itemBtn' 
                        onClick={() => {
                            if (item.name === 'orders') {
                                // window.open(`https://tpoapp.pro/client/static/${id}`)
                                navigate(`/account/${id}`)
                            } else {
                                navigate(`/profile/${item.name}`)
                            }
                        }}
                        style={{
                            backgroundColor: location.pathname === `/profile/${item.name}` && 'rgba(172, 172, 172, 0.50)',
                            filter: location.pathname === `/profile/${item.name}` && 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10))',
                            border: location.pathname === `/profile/${item.name}` && '1px solid #dadada',
                            color: location.pathname === `/profile/${item.name}` && '#fff',
                        }}>
                        <img src={item.icon} alt='' />
                        <span>
                            {item.text}
                        </span>   
                        {'/profile/languages' === `/profile/${item.name}` && <img src={ArrowDown} className='arrow'/>}                 
                    </div>
                ))}
            </div>

            {true && 
            <div className='ProfileSideBar__container__signOutBtn' onClick={() => {handleLogout()}}>
                <img src={SignOutIcon} alt="" />
                <span>
                    Sign Out
                </span>
            </div>}
        </div>
    );
}
 
export default ProfileSideBar;