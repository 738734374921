import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@iconify/react/dist/iconify.js'
import './SalePageCounter.scss'
// import { setCartElemCurrentUnit, setAllItemsCart, setCartElemQuantityValue } from '../../../store/slices/itemsCart'
import { allItemsCartSlice, setAllItemsCart, setCartElemCurrentUnit, setCartElemQuantityValue, setItemsCart } from './../../../store/slices/allItemsCart';
import useFindInCart from '../../../hooks/useFindInCart';

const SalePageCounter = ({ item }) => {
  const [cardUnits, setCardUnits] = useState(['kg'])
  // console.log(cardUnits);
  const dispatch = useDispatch()
  const convertedCart = useSelector((state) => state.allItemsCart.allItemsCart);
  const inCart = useFindInCart(item);

  function salePrice(quantity) {
    let price = 0
    item.priceRange.forEach(element => {
      if (quantity <= element.minQuantity) {
        price = element.price
      }
    });
    return price
  }

  
  const [showQuantity, setShowQuantity] = useState()
  const [itemSettings, setItemSettings] = useState({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})

  useEffect(() => {
    setItemSettings({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})
    setShowQuantity(parseFloat((inCart?.quantity ? inCart.quantity : 0)))
  }, [inCart])

  
  const handleSetCount = (value) => {
    setShowQuantity(parseFloat(value))
    setItemSettings({...itemSettings, quantity: parseFloat(value)})
    !inCart && parseFloat(value) > 0 && dispatch(setAllItemsCart(item))
    inCart && parseFloat(value) <= 0 && dispatch(setAllItemsCart(item))
    dispatch(setCartElemQuantityValue({ value: parseFloat(value), elem: item }))
    dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku }, value: itemSettings.unit }))
  }
  const handleDecrement = () => {
    if (itemSettings.quantity >= 5) {
      setItemSettings({...itemSettings, quantity: parseFloat(itemSettings.quantity - 5)})
    }
    if (itemSettings.quantity <= 5) {
      setItemSettings({quantity: 0, unit: item?.unit})
      inCart && dispatch(setAllItemsCart(item))
    }
    dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity-5).toFixed(1)), elem: item }))
    dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku }, value: itemSettings.unit }))
  }
  const handleIncrement = () => {
    let itemFixed = {...item, spoPrice: salePrice(item.eventCurrentSaleQunatity+parseInt(inCart?.quantity?inCart.quantity:0))}
    delete itemFixed.eventCurrentSaleQunatity
    delete itemFixed.eventStockQuantity
    delete itemFixed.currentPriceRangeId
    delete itemFixed.priceRange
    console.log(itemFixed);

    if (!inCart) {
      dispatch(setAllItemsCart(itemFixed))
      dispatch(setCartElemQuantityValue({ value: parseFloat((5).toFixed(1)), elem: itemFixed }))
    }
    inCart && dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity+5).toFixed(1)), elem: itemFixed }))
    dispatch(setCartElemCurrentUnit({ elem: { sku: itemFixed?.sku }, value: itemSettings.unit }))
  }

  return (
    <div className='SalePageCounter' key={item._id+'Counter_salePage'}>
      {inCart && <div 
        className='SalePageCounter__decriment'
        onClick={handleDecrement}
      >
        <Icon icon="akar-icons:minus" />
      </div>}
      <div className='SalePageCounter__weight'>
        {inCart?.quantity ? inCart.quantity : 0}kg
      </div>
      <div 
        className='SalePageCounter__incriment'
        onClick={() => (item.eventCurrentSaleQunatity+parseInt(inCart?.quantity?inCart.quantity:0)) < item.eventStockQuantity ? handleIncrement() : handleSetCount((item.eventStockQuantity-item.eventCurrentSaleQunatity).toFixed(1))}
      >
        <Icon icon="akar-icons:plus" />
      </div>
    </div>
  )
}

export default SalePageCounter