import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allItemsFavorites: localStorage.getItem('allItemsFavorite') ? JSON.parse(localStorage.getItem('allItemsFavorite')) : [],
}

export const allItemsFavoritesSlice = createSlice({
  name: 'allItemsFavorites',
  initialState,
  reducers: {
    setItemsFavorites: (state, action) => {
      state.allItemsFavorites = [...action.payload]
    },
    setAllItemsFavorites: (state, action) => {
      const filterArray = state.allItemsFavorites.map((elem) => elem._id)
      if (!filterArray.includes(action.payload._id)) {
        if (action.payload.quantity === 0) {
          state.allItemsFavorites = [...state.allItemsFavorites, { ...action.payload, quantity: 1 }]
        } else {
          state.allItemsFavorites = [...state.allItemsFavorites, action.payload]
        }
      } else {
        state.allItemsFavorites = state.allItemsFavorites.filter((item) => item._id !== action.payload._id)
      }
    },
    setFavoritesElemQuantityIncrement: (state, action) => {
      const itemIndex = state.allItemsFavorites.findIndex((item) => item._id === action.payload._id)
      if (itemIndex >= 0) {
        state.allItemsFavorites[itemIndex].quantity = parseFloat(state.allItemsFavorites[itemIndex].quantity)
          ? parseFloat(state.allItemsFavorites[itemIndex].quantity) + 1
          : 1
      }
    },
    setFavoritesElemQuantityDecrement: (state, action) => {
      const itemIndex = state.allItemsFavorites.findIndex((item) => item._id === action.payload._id)
      if (itemIndex >= 0) {
        state.allItemsFavorites[itemIndex].quantity = parseFloat(state.allItemsFavorites[itemIndex].quantity)
          ? parseFloat(state.allItemsFavorites[itemIndex].quantity) - 1
          : 0
      }
      if (parseFloat(state.allItemsFavorites[itemIndex].quantity) <= 0) {
        state.allItemsFavorites[itemIndex].quantity = 0
      }
    },
    setFavoritesElemQuantityValue: (state, action) => {
      const itemIndex = state.allItemsFavorites.findIndex((item) => item._id === action.payload.elem._id)
      if (itemIndex >= 0) {
        state.allItemsFavorites[itemIndex].quantity = action.payload.value
      }
    },
    setFavoritesElemCurrentUnit: (state, action) => {
      const itemIndex = state.allItemsFavorites.findIndex((item) => item._id === action.payload.elem._id)
      if (itemIndex >= 0) {
        state.allItemsFavorites[itemIndex].currentUnit = action.payload.value
      }
    },
    deleteFavoritesElem: (state, action) => {
      state.allItemsFavorites = state.allItemsFavorites.filter((item) => item._id !== action.payload._id)
    },
  },
})

export const {
  setItemsFavorites,
  deleteFavoritesElem,
  setAllItemsFavorites,
  setFavoritesElemCurrentUnit,
  setFavoritesElemQuantityDecrement,
  setFavoritesElemQuantityIncrement,
  setFavoritesElemQuantityValue,
} = allItemsFavoritesSlice.actions

export default allItemsFavoritesSlice.reducer
