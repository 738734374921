import HomeIcon from '../pages/components/assets/images/home-line.png';
import PersonIcon from '../pages/components/assets/images/Person-line.svg';
import OrderIcon from '../pages/components/assets/images/List-line.svg';
import LangIcon from '../pages/components/assets/images/Earth-line.svg';
import AmbassadorIcon from '../pages/components/assets/images/group-line.svg'

const sideBarTab = [
    {
        name: 'home',
        text: 'Home',
        icon: HomeIcon,
    },
    {
        name: 'info',
        text: 'Datos personales',
        icon: PersonIcon,
    },
    {
        name: 'orders',
        text: 'Mis pedidos',
        icon: OrderIcon,
    },
    // {
    //     name: 'Ambassador',
    //     text: 'Ambassador',
    //     icon: AmbassadorIcon,
    // },
    {
        name: 'languages',
        text: 'Idioma',
        icon: LangIcon,
    }
]

export default sideBarTab