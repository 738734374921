import { createSlice } from '@reduxjs/toolkit'
import buttonsCategories from '../../constants/categoryButtons'

const initialState = {
    categoryButtonsTab: {
      active: buttonsCategories[1],
    },
    pageMore: {
        openPageMore: false,
    },
    profilePage: {
      ambassador: false
    },
    previewNav: {
      openMoreTab: false
    },
    orderPageMobile: {
      ordersTab: 'Todo'
    },
    uiModal: {
      filterModal: false,
      favoriteModal: false,
      itemModal: {item: {},  isShow: false},
      cartModal: false,
      warnModal: false,
    },
    cartElemInfo: false,
    isLogin: false,
    idLastOrder: '',
    paymentMetod: localStorage.getItem('paymentMethod') ? localStorage.getItem('paymentMethod') : 'Pick Up-',
    proMode: 2,
    PriceIVA: false,
    grammMode: false,
}


export const createOrderDataSlice = createSlice({
  name: 'contextUI',
  initialState,
  reducers: {
    setAllContextUI: (state = initialState, action) => {
      state = action.payload
    },
    setCategoryButtonsTab(state = initialState, action) {
      state.categoryButtonsTab.active = action.payload
    },
    setOpenPageMore(state = initialState, action) {
      state.pageMore.openPageMore = action.payload
    },
    setAmbassador(state = initialState, action) {
      state.profilePage.ambassador = action.payload
    },
    setMoreTab(state = initialState, action) {
      state.previewNav.openMoreTab = action.payload
    },
    setOrderMobileTab(state = initialState, action) {
      state.orderPageMobile.ordersTab = action.payload
    },
    setUiFilterModal(state = initialState, action) {
      state.uiModal.filterModal = action.payload
    },
    setUiFavoriteModal(state = initialState, action) {
      state.uiModal.favoriteModal = action.payload
    },
    setCartElemInfo(state = initialState, action) {
      state.cartElemInfo = action.payload
    },
    setIsLogin(state = initialState, action) {
      state.isLogin = action.payload
    },
    setIdLastOrder(state = initialState, action) {
      state.idLastOrder = action.payload
    },
    setItemModal(state = initialState, action) {
      state.uiModal.itemModal = action.payload
    },
    setCartModal(state = initialState, action) {
      state.uiModal.cartModal = action.payload
    },
    setWarnModal(state = initialState, action) {
      state.uiModal.warnModal = action.payload
    },
    setPaymentMetod(state = initialState, action) {
      state.paymentMetod = action.payload
    },
    setProMode(state = initialState, action) {
      state.proMode = action.payload
    },
    setPriceIvaMode(state = initialState, action) {
      state.PriceIVA = action.payload
    },
    setGramMode(state = initialState, action) {
      state.grammMode = action.payload
    },
  },
})

export const {
    setAllContextUI,
    setCategoryButtonsTab,
    setOpenPageMore,
    setAmbassador,
    setMoreTab,
    setOrderMobileTab,
    setUiFilterModal,
    setUiFavoriteModal,
    setCartElemInfo,
    setIsLogin,
    setIdLastOrder,
    setItemModal,
    setCartModal,
    setWarnModal,
    setPaymentMetod,
    setProMode,
    setPriceIvaMode,
    setGramMode
} = createOrderDataSlice.actions

export default createOrderDataSlice.reducer
