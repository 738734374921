import React, { useState } from 'react'
import './FilterModal.scss'
import { Slider, Switch } from '@mui/material'
import WhiteCloseIcon from '../../assets/images/WhiteCloseIcon.svg'
import { setUiFilterModal } from '../../../../store/slices/contextUI'
import { useDispatch, useSelector } from 'react-redux'


const FilterModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector((store) => store.contextUI.uiModal.filterModal)

  const marks = [
    {
      value: 0,
      label: '€0',
    },
    {
      value: 10,
      label: '€10',
    },
    {
      value: 20,
      label: '€20',
    },
    {
      value: 30,
      label: '€30',
    },
    {
      value: 40,
      label: '€40',
    },
    { 
      value: 50,
      label: '€50',
    },
  ];

  const [priceValue, setPriceValue] = useState([0, 50]);
  const handleChangePrice = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceValue([Math.min(newValue[0], priceValue[1]), priceValue[1]]);
    } else {
      setPriceValue([priceValue[0], Math.max(newValue[1], priceValue[0])]);
    }
  };

  const [offerSwitch, setOfferSwitch] = useState(false)
  
  return (
    <div className='FilterModal'>
      {isOpen && <><div
        className='FilterModal__background'
        onClick={() => {dispatch(setUiFilterModal(false))}}
      ></div>
      <div className='FilterModal__modal'>
        <div className='FilterModal__modal__header'>
          Filtros
          <img src={WhiteCloseIcon} alt="X" onClick={() => {dispatch(setUiFilterModal(false))}}/>
        </div>
        <div className='FilterModal__modal__filter'>
          <div className='FilterModal__modal__filter__price'>
            <span className='FilterModal__modal__filter__price__text'>Price</span>
            <Slider
              className='FilterModal__modal__filter__priceSlider'
              getAriaLabel={() => 'Minimum distance'}
              value={priceValue}
              // onMouseMove={handleMouseOverPrice}
              onChange={handleChangePrice}
              disableSwap sx={{ color: '#5FC56E'}}
              max={50}
              step={1}
              marks={marks}
            />
            <div className='FilterModal__modal__filter__priceInput'>
              <div className='FilterModal__modal__filter__priceInput__left'>
                <span>From</span>
                <div className='input'>
                  €
                  <input
                    type="text"
                    value={priceValue[0]}
                    onChange={(e) => {setPriceValue([e.target.value, priceValue[1]])}}
                  />
                </div>
              </div>
              <div className='FilterModal__modal__filter__priceInput__right'>
                <span>Until</span>
                <div className='input'>
                  €
                  <input 
                    type="text"
                    value={priceValue[1]}
                    onChange={(e) => {setPriceValue([priceValue[0], e.target.value])}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='FilterModal__modal__filter__offer'>
            Mostrar sólo ofertas especiales
            <Switch  
              className='FilterModal__modal__filter__offer__switch'
              checked={offerSwitch}
              onChange={() => {setOfferSwitch(!offerSwitch)}}
              sx={{
                width: '68px',
                '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                    width: '66px'},
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#fff',
                    widows: '20px',
                },
                '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#5FC56E !important'},
                '& .MuiSwitch-track': {

                        height: '24px',
                        width: '70px',
                        borderRadius: '14px',

                        transform: 'translateX(-5px) translateY(-5px)'
                    }
                }}
              />
          </div>
        </div>
        <div className='FilterModal__modal__footer'>
          <div
            className='FilterModal__modal__footer__btn'
            onClick={() => {dispatch(setUiFilterModal(false))}}
          >Aplicar</div>
        </div>
      </div></>}
    </div>
  )
}

export default FilterModal