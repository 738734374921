import React from 'react'
import { useTranslation } from 'react-i18next'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (event, newLanguage) => {
    if (newLanguage !== null) {
      i18n.changeLanguage(newLanguage)
    }
  }

  return (
    <ToggleButtonGroup value={i18n.language} exclusive onChange={changeLanguage} aria-label='language'>
      <ToggleButton value='en' aria-label='english'>
        English
      </ToggleButton>
      <ToggleButton value='es' aria-label='spanish'>
        Español
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default LanguageSwitcher
