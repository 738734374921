import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allItemsCart: localStorage.getItem('allItemsCart') ? JSON.parse(localStorage.getItem('allItemsCart'))?.map((item) => {
    delete item.timestamp;
    return item;
  }) : [],
}

export const allItemsCartSlice = createSlice({
  name: 'allItemsCart',
  initialState,
  reducers: {
    setItemsCart: (state, action) => {
      state.allItemsCart = [...action.payload]
    },
    setClearCart: (state, action) => {
      state.allItemsCart = []
    },
    setAllItemsCart: (state, action) => {
      const filterArray = state.allItemsCart.map((elem) => elem.sku)
      if (!filterArray.includes(action.payload.sku)) {
        if (action.payload.quantity === 0) {
          state.allItemsCart = [...state.allItemsCart, { ...action.payload, quantity: 1, quantityOrder: 1, timestamp: new Date().getTime(), }]
        } else {
          state.allItemsCart = [...state.allItemsCart, action.payload]
        }
      } else {
        state.allItemsCart = state.allItemsCart.filter((item) => item.sku !== action.payload.sku)
      }
    },
    setCartElemQuantityIncrement: (state, action) => {
      const itemIndex = state.allItemsCart.findIndex((item) => item.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantity = parseFloat(state.allItemsCart[itemIndex].quantity)
          ? parseFloat(state.allItemsCart[itemIndex].quantity) + 1
          : 1
      }
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantityOrder = parseFloat(state.allItemsCart[itemIndex].quantityOrder)
          ? parseFloat(state.allItemsCart[itemIndex].quantityOrder) + 1
          : 1
      }
      if (itemIndex >= 0 && state.allItemsCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.allItemsCart[itemIndex]}
        state.allItemsCart = [...state.allItemsCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
    },
    setCartElemQuantityDecrement: (state, action) => {
      const itemIndex = state.allItemsCart.findIndex((item) => item?.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantity = parseFloat(state.allItemsCart[itemIndex].quantity)
          ? parseFloat(state.allItemsCart[itemIndex].quantity) - 1
          : 0
          if (state.allItemsCart[itemIndex].sku === action.payload.sku) {
            const item = {...state.allItemsCart[itemIndex]}
            state.allItemsCart = [...state.allItemsCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
          }
      }
      if (parseFloat(state.allItemsCart[itemIndex].quantity) <= 0) {
        state.allItemsCart[itemIndex].quantity = 0
      }
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantityOrder = parseFloat(state.allItemsCart[itemIndex].quantityOrder)
          ? parseFloat(state.allItemsCart[itemIndex].quantityOrder) - 1
          : 0
      }
      if (parseFloat(state.allItemsCart[itemIndex].quantityOrder) <= 0) {
        state.allItemsCart[itemIndex].quantityOrder = 0
      }
    },
    setCartElemQuantityValue: (state, action) => {
      const itemIndex = state.allItemsCart.findIndex((item) => item?.sku === action.payload.elem?.sku)
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantity = parseFloat(action.payload.value)
      }
      if (itemIndex >= 0 && state.allItemsCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.allItemsCart[itemIndex]}
        state.allItemsCart = [...state.allItemsCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantityOrder = parseFloat(action.payload.value)
      }
    },
    setCartElemQuantityOrderValue: (state, action) => {
      const itemIndex = state.allItemsCart.findIndex((item) => item?.sku === action.payload.elem?.sku)
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].quantityOrder = action.payload.quantityOrder
      }
      if (itemIndex >= 0 && state.allItemsCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.allItemsCart[itemIndex]}
        state.allItemsCart = [...state.allItemsCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
    },
    setCartElemCurrentUnit: (state, action) => {
      const itemIndex = state.allItemsCart.findIndex((item) => item?.sku === action.payload.elem.sku)
      if (itemIndex >= 0) {
        state.allItemsCart[itemIndex].currentUnit = action.payload.value
      }
      if (itemIndex >= 0 && state.allItemsCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.allItemsCart[itemIndex]}
        state.allItemsCart = [...state.allItemsCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date.getTime()}, ]
      }
    },
    deleteCartElem: (state, action) => {
      state.allItemsCart = state.allItemsCart.filter((item) => item?.sku !== action.payload.sku)
    },
  },
})

export const {
  setClearCart,
  setItemsCart,
  deleteCartElem,
  setAllItemsCart,
  setCartElemCurrentUnit,
  setCartElemQuantityDecrement,
  setCartElemQuantityIncrement,
  setCartElemQuantityValue,
  setCartElemQuantityOrderValue
} = allItemsCartSlice.actions

export default allItemsCartSlice.reducer
