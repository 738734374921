import React, { useState, useEffect, useMemo } from 'react'
import { mainApi } from '../../../../../store/services/mainApi'
import { CircularProgress } from '@mui/material'
import './PreviewList.scss'
import { useNavigate } from 'react-router'

const PreviewList = () => {
    const navigate = useNavigate()
    const { data: CategoryList, isLoading: isLoadingCategory } = mainApi.useGetCategoryListQuery()
    const { data: brandsList, isLoading: isLoadingBrands } = mainApi.useGetBrandsListQuery()
    const { data: BusinessTypeList, isLoading: isLoadingBusiness } = mainApi.useGetBusinessTypeListQuery()

    const handleGoToCategory = (searchType) => {
        navigate(`/categories/${searchType}`)
    }


    const handleGoToCategoryItem = (searchType, category) => {
        navigate(`/categories/${searchType}/${category}`)
    }

    return (
        <div className='Preview'>
        <div className='PreviewList'>
            <div className='PreviewList__title' onClick={() => handleGoToCategory('category')}>
                Categories
            </div>
            <div className='PreviewList__categories' onScroll={() => console.log('qwe')}>
              {isLoadingCategory ? <CircularProgress /> :
              CategoryList.map((item, index) => (
                <div
                  key={item.category+'PrevList'+index}
                  className='PreviewList__categories__item'
                  onClick={() => handleGoToCategoryItem('category', item.category)}
                >
                    <img src={item.categoryImage} alt={item.category}/>
                    {item.category}
                </div>
              ))}
            </div>
            <div className='PreviewList__title' onClick={() => handleGoToCategory('brand')}>
                Brands
            </div>
            <div className='PreviewList__categories'>
                {isLoadingBrands ? <CircularProgress /> :
                brandsList.map((brand, index) => (
                    <div
                      key={brand.brand+'PrevList'+index}
                      className='PreviewList__categories__item'
                      onClick={() => handleGoToCategoryItem('brand', brand.brand)}
                    >
                        <img src={brand.brandImage} alt={brand.brand} />
                    </div>
                ))}
            </div>
            <div className='PreviewList__title' onClick={() => handleGoToCategory('businessType')}>
                Business Types
            </div>
            <div className='PreviewList__categories'>
                {isLoadingBusiness ? <CircularProgress /> :
                BusinessTypeList.map((businessType, index) => (
                    <div
                      key={businessType.businessType+'PrevList'+index}
                      className='PreviewList__categories__item'
                      onClick={() => handleGoToCategoryItem('businessType', businessType.businessType)}
                    >
                        <img src={businessType.businessTypeImage} alt={businessType.businessType} />
                    </div>
                ))}
            </div>
        </div>
        </div>
)}

export default PreviewList
