import React, { useState, useEffect } from 'react'
import { Box, Switch } from '@mui/material'
import './StyledBox.scss'
import { styled } from '@mui/material/styles'
import { ReactComponent as Lock } from '../../components/assets/images/LockIcon.svg'
import { ReactComponent as UnLock } from '../../components/assets/images/UnLockIcon.svg'

import lock from '../../components/assets/images/LockIcon.svg'
import galochka from '../../components/assets/images/galochkaStatic.svg'
const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        backgroundColor: '#2A2D2F',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,

    backgroundImage: `url(${galochka})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: '#176318',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default function StyledBox({ headerText, defaultVisible, sx, children, ...rest }) {
  const [visible, setVisible] = useState(defaultVisible ? defaultVisible : true)
  const [blur, setBlur] = useState(false)

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  return (
    <Box sx={{ ...sx, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} {...rest}>
      <Box
        sx={{
          width: '100%',
          // height: '5%',
          backgroundColor: '#CBDCCB',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          // border: '1px solid #CCCCCC',

          // padding: '5px',

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ margin: '5px' }}>
          <IOSSwitch checked={visible} onChange={() => setVisible(!visible)} />
        </Box>
        {headerText && <Box sx={{ margin: '5px', color: '#286F29', textTransform: 'uppercase' }}>{headerText}</Box>}
        <Box sx={{ marginRight: '10px' }}>{blur ? <Lock onClick={() => setBlur(false)} /> : <UnLock onClick={() => setBlur(true)} />}</Box>
      </Box>

      {visible ? (
        <Box
          sx={{
            width: '100%',
            // height: '95%',
            backgroundColor: '#fff',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            // borderLeft: '1px solid #CCCCCC',
            // borderRight: '1px solid #CCCCCC',
            // borderBottom: '1px solid #CCCCCC',
            // padding: '5px',
          }}
        >
          {blur ? (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  //blur effect if need
                  filter: blur ? 'blur(5px)' : 'none',
                }}
              >
                {children}
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <UnLock width={150} height={150} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ margin: '0px 5px 0px 5px' }}>{children}</Box>
          )}
        </Box>
      ) : null}
    </Box>
  )
}
