import React, { useEffect, useState } from 'react'
import './CategoryFilterModal.scss'
import CloseIcon from '../../../components/assets/images/close_icon.svg'
import { useParams } from 'react-router';
import { mainApi } from '../../../../store/services/mainApi';
import { CircularProgress, Switch } from '@mui/material';
import FilterModalElement from './FilterModalElement/FilterModalElement';

const CategoryFilterModal = ({setFilterCategory, filterCategory}) => {
  const tradeName = useParams().tradeName

  const { data: orderData, isLoading: isLoadingOrder } = mainApi.useGetOrderItemsByTradeNameQuery(tradeName);
  const [mapList, setMapList] = useState([]);
  useEffect(() => {
    if (!isLoadingOrder && orderData !== undefined) {
      const array = orderData
      setMapList([...array]?.sort(
        function(a, b) {
          if (a.name < b.name) return -1
        }
      ));
    }
  }, [orderData, localStorage])
  

  const [change, setChange] = useState(false);
  return (
    filterCategory ?
    <div className='CategoryFilterModal__background' onClick={() => {setFilterCategory(false); location.reload();}}>
      <div className='CategoryFilterModal' onClick={(e) => e.stopPropagation()}>
        <div className='CategoryFilterModal__header'>
          <span>Ocultar del catálogo</span>
          <div
          onClick={() => {setFilterCategory(false); location.reload();}}
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className='CategoryFilterModal__body'>
          {isLoadingOrder ? <CircularProgress/> :
            mapList?.map((item) => item?.enabled == 1 &&
            <FilterModalElement setChange={setChange} change={change} item={item} key={'CategoryFilterModal__body__item__'+item?._id}/>)
          }
        </div>
      </div>
    </div>
    : <></>
  )
}

export default CategoryFilterModal