import React from 'react'
import './FavoriteModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setUiFavoriteModal } from '../../../../store/slices/contextUI';
import ContentCard from '../../Content/ContentCard/ContentCard';


const FavoriteModal = () => {
  const dispatch = useDispatch();
  const allFavoriteCart = useSelector(state => state.allItemsFavorites.allItemsFavorites)
  console.log(allFavoriteCart);

  return (
    <div className='FavoriteModal' onClick={() => dispatch(setUiFavoriteModal(false))}>
      <div className='FavoriteModal__content' onClick={(e) => e.stopPropagation()}>
        <div className='FavoriteModal__content__title'>
          Favoritos
          <span onClick={() => dispatch(setUiFavoriteModal(false))}>X</span>
        </div>
        <div className='FavoriteModal__content__items'>
          {allFavoriteCart.length !== 0 ? 
          allFavoriteCart.map((item, index) => 
          <ContentCard card={item} key={'FavoritesCart_Item'+index} />
        )
          :
          <div>No hay elementos favoritos</div>
          }
        </div>
      </div>
    </div>
  )
}

export default FavoriteModal