import React from 'react'
import './HomeTab.scss'
import { useNavigate, useParams } from 'react-router'
import BarTab from '../HomePage/BarTab/BarTab'
import CitchenTab from '../HomePage/CitchenTab/CitchenTab'
import StoreTab from '../HomePage/StoreTab/StoreTab'

const HomeTab = () => {
  const tabParams = useParams().tab

  return (
    <div className='HomeTab'>
      {tabParams === 'Bar' ? 
        <BarTab /> 
      : tabParams === 'Kitchen' ? <CitchenTab /> : <StoreTab/>
      }  
    </div>
  )
}

export default HomeTab