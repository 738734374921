import React, { useEffect, useState } from 'react'
import './PreviewFilter.scss'
import CatalogModal from '../../../ModalWindows/CatalogModal/CatalogModal'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { mainApi } from '../../../../../store/services/mainApi'
import buttonsCategories from '../../../../../constants/categoryButtons'
import { setFiltersSection } from '../../../../../store/slices/filterData'

const PreviewFilter = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const filters = useSelector((state) => state.filterData.filterData.filters)
    const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
        pollingInterval: 50000,
      })
    const allSectionProducts = allProductsData?.filter(product => product.section?.find(el => filters.section.includes(el)))
    
    const [catalogModal, setCatalogModal] = useState(false)
    const [openTab, setOpenTab] = useState('');

    
    
    const [filterCategoryList, setFilterCategoryList] = useState([])
    const { data: CategoryList, isLoading: isLoadingCategory } = mainApi.useGetCategoryListQuery()
    // console.log('CategoryList', CategoryList);
    useEffect(() => {
        if (!isLoading) {
        const filterProducts = allProductsData?.map(el => el.category?.split(',')).flat()
        const filterCategory = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
        // console.log(CategoryList);
        // console.log(filterCategory);
        setFilterCategoryList(CategoryList?.filter(el => (filterCategory.includes(el.category))));
        } 
    }, [CategoryList, filters, isLoading])

    const [filterdeBusinessTypeList, setFilterdeBusinessTypeList] = useState([])
    const { data: BusinessTypeList, isLoading: isLoadingBusiness } = mainApi.useGetBusinessTypeListQuery()
    useEffect(() => {
        if (!isLoading) {
        const filterProducts = allProductsData?.map(el => el.businessType?.split(',')).flat()
        const filterBusinessType = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
        // console.log(BusinessTypeList);
        // console.log(filterBusinessType);
        setFilterdeBusinessTypeList(BusinessTypeList?.filter(el => (filterBusinessType.includes(el.businessType))));
        } 
    }, [BusinessTypeList, filters, isLoading])

    const { data: brandsList, isLoading: isLoadingBrands } = mainApi.useGetBrandsListQuery()
    const [filterBrandsList, setFilterBrandsList] = useState([])
    useEffect(() => {
        if (!isLoading) {
          const filterProducts = allProductsData?.map(el => el.brand?.split(',').map(el => el?.replace('Marca ', ''))).flat()
          const filterBrandList = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
          // console.log(brandsList);
          // console.log(filterBrandList);
          setFilterBrandsList(brandsList?.filter(el => (filterBrandList.includes(el.brand))));
        } 
    }, [brandsList, filters, isLoading])

    //Beta Functional
    const [specializationList, setSpecializationList] = useState([])
    useEffect(() => {
        if (!isLoading) {const allFilterdata = allProductsData.map(el => el.specialization)
        const specializationList = Array.from(new Set(allFilterdata))
        setSpecializationList(specializationList)}
      }, [allProductsData, filters])

    return (
        <div className="PreviewFilter__background">
            <div className='PreviewFilter'>
                <div
                  className='PreviewFilter__catalog'
                >
                  <span>Elija por favor</span>
                  {/* <img src={ArrowDown} alt='More'/> */}
                </div>
                {catalogModal && <CatalogModal setCatalogModal={setCatalogModal}/>}

                <div className='PreviewFilter__container'>
                    <button
                        className='PreviewFilter__button'
                        onClick={() => {setOpenTab(openTab === 'Section' ? '' : 'Section'); }}
                        onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                        style={{ borderBottom: openTab === 'Section' ? '1px solid #5FC56E' : 'transparent' }}
                    >
                        Sectores
                    </button> 
                    {openTab === 'Section' &&
                    <div 
                        className='PreviewFilter__container__items'>
                        {buttonsCategories.map((item) => (
                            <div 
                                className='PreviewFilter__container__items__item'
                                key={'catalogModal'+item.name}
                                onClick={() => {navigate('/home');
                                dispatch(setFiltersSection([item.name]));
                                // dispatch(setCategoryButtonsTab(buttonsCategories.find((el) => el.name === item.name)))
                                navigate('/home/'+item.tab)
                            }}
                            >
                                <img src={item.icon} alt="" />
                                {item.name}
                            </div>
                        ))}
                    </div>}
                </div>
                <div className='PreviewFilter__container'>
                    <button
                        className='PreviewFilter__button'
                        onClick={() => {setOpenTab(openTab === 'Categories' ? '' : 'Categories'); }}
                        onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                        style={{ borderBottom: openTab === 'Categories' ? '1px solid #5FC56E' : 'transparent' }}
                    >
                        Categorías
                    </button> 
                    {openTab === 'Categories' &&
                    <div 
                        className='PreviewFilter__container__items'>
                        {!isLoadingCategory && filterCategoryList.map((item) => (
                            <div 
                                className='PreviewFilter__container__items__item'
                                key={'catalogModal'+item.name}
                                onClick={() => {
                                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                                    navigate('/categories/category/'+item.category)
                                }}
                            >
                                <img src={item.categoryImage} alt="" />
                                {item.category}
                            </div>
                        ))}
                    </div>}
                </div>
                <div className='PreviewFilter__container'>
                    <button
                        className='PreviewFilter__button'
                        onClick={() => {setOpenTab(openTab === 'Cuisine' ? '' : 'Cuisine'); }}
                        onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                        style={{ borderBottom: openTab === 'Cuisine' ? '1px solid #5FC56E' : 'transparent' }}
                    >
                        Perfil
                    </button> 
                    {openTab === 'Cuisine' &&
                    <div 
                        className='PreviewFilter__container__items'>
                        {!isLoadingBusiness && filterdeBusinessTypeList.map((item) => (
                            <div 
                                className='PreviewFilter__container__items__item'
                                key={'catalogModal'+item.name}
                                onClick={() => {
                                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                                    navigate('/categories/businessType/'+item.businessType)}}
                            >
                                <img src={item.businessTypeImage} alt="" />
                                {item.businessType}
                            </div>
                        ))}
                    </div>}
                </div>
                <div className='PreviewFilter__container'>
                    <button
                        className='PreviewFilter__button'
                        onClick={() => {setOpenTab(openTab === 'Brand' ? '' : 'Brand'); }}
                        onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                        style={{ borderBottom: openTab === 'Brand' ? '1px solid #5FC56E' : 'transparent' }}
                    >
                        Marcas
                    </button> 
                    {openTab === 'Brand' &&
                    <div 
                        className='PreviewFilter__container__items'>
                        {!isLoadingBrands && filterBrandsList.map((item) => (
                            <div 
                                className='PreviewFilter__container__items__item'
                                key={'catalogModal'+item.name}
                                onClick={() => {
                                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                                    navigate('/categories/brand/'+item.brand)}}
                            >
                                <img src={item.brandImage} alt="" />
                                {item.brand}
                            </div>
                        ))}
                    </div>}
                </div>
                <div className='PreviewFilter__container'>
                    <button
                        className='PreviewFilter__button'
                        onClick={() => {setOpenTab(openTab === 'Specialization' ? '' : 'Specialization'); }}
                        onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                        style={{ borderBottom: openTab === 'Specialization' ? '1px solid #5FC56E' : 'transparent' }}
                    >
                        Soluciones
                    </button> 
                    {openTab === 'Specialization' &&
                    <div 
                        className='PreviewFilter__container__items'>
                        {!isLoading && specializationList.map((item) => (
                            <div 
                                className='PreviewFilter__container__items__item'
                                key={'catalogModal'+item}
                                onClick={() => {
                                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                                    navigate('/specialization/'+item)}}
                            >
                                {/* <img src={''} alt="" /> */}
                                {item}
                            </div>
                        ))}
                    </div>}
                </div>

            </div>
        </div>
)}

export default PreviewFilter