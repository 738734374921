import BarIcon from '../pages/components/assets/images/BarIcon.svg'
import NeveraIcon from '../pages/components/assets/images/NeveraIcon.svg'
import AlmacenIcon from '../pages/components/assets/images/AlmacenIcon.svg'
import FrescoIcon from '../pages/components/assets/images/FreskoIcon.svg'
import CongeladorIcon from '../pages/components/assets/images/CongeladorIcon.svg'


const buttonsCategoriesSlider = [
    {
        icon: BarIcon,
        img: 'https://i.imgur.com/bBwBHne.png',
        name: 'Alcohol'
    },
    {
        icon: FrescoIcon,
        img: 'https://i.imgur.com/LjJTyo1.png',
        name: 'Fruit & Vegetables'
    },
    {
        icon: NeveraIcon,
        img: 'https://i.imgur.com/fFcLNnA.png',
        name: 'Proteins'
    },
    {
        icon: AlmacenIcon,
        img: 'https://i.imgur.com/7ixIgcD.png',
        name: 'Pantry'
    },
    {
        icon: CongeladorIcon,
        img: 'https://i.imgur.com/PE7VAY3.png',
        name: 'Frozen'
    },
]

export default buttonsCategoriesSlider