import React, { useEffect, useMemo, useState } from 'react'
import './AllTab.scss'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { mainApi } from '../../store/services/mainApi'
import { CircularProgress } from '@mui/material'
import SettingsIcon from '../components/assets/images/settingsIcon.svg'
import AddIcon from '../components/assets/images/addIcon.svg'
import { setUiFilterModal, setUiFavoriteModal } from '../../store/slices/contextUI'

const AllTab = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const { data: category, isLoading: isLoadingCategory } = mainApi.useGetCategoryListQuery()
  const { data: brand, isLoading: isLoadingBrand } = mainApi.useGetBrandsListQuery()
  const { data: businessType, isLoading: isLoadingBusiness } = mainApi.useGetBusinessTypeListQuery()
  const modPage = useParams().mod
  const tabPage = useParams().tab

  const handleGoToCategory = (searchType, category) => {
    navigate(`/categories/${searchType}/${category}`)
  }

  const filters = useSelector((state) => state.filterData.filterData.filters)

  const [categoryMapList, setCategoryMapList] = useState([])
  const [bussinessMapList, setBussinessMapList] = useState([])
  const [brandMapList, setBrandMapList] = useState([])

  useEffect(() => {
    if (!isLoadingCategory && !isLoading) {
      let filterArray = allProductsData?.filter(product => product.section?.find(el => el.includes([tabPage]))).map(el => el.category?.split(',')).flat()?.filter(el => el !== undefined)
      const categoryListFiltered = [...new Set(filterArray)]
      // console.log(categoryListFiltered);
      setCategoryMapList(category?.filter(el => categoryListFiltered.includes(el.category)).sort((a, b) => (a.category > b.category) ? 1 : -1));
    }
  }, [allProductsData, category, tabPage])

  useEffect(() => {
    if (!isLoadingBusiness && !isLoading) {
      let filterArray = allProductsData?.filter(product => product.section?.find(el => el.includes([tabPage]))).map(el => el.businessType?.split(',')).flat()?.filter(el => el !== undefined)
      const categoryListFiltered = [...new Set(filterArray)]
      console.log(categoryListFiltered);
      console.log('all');
      console.log(businessType);
      setBussinessMapList(businessType?.filter(el => categoryListFiltered.includes(el.businessType)).sort((a, b) => (a.businessType > b.businessType) ? 1 : -1));
    }
  }, [allProductsData, businessType, tabPage])

  useEffect(() => {
    if (!isLoadingBusiness && !isLoading) {
      let filterArray = allProductsData?.filter(product => product.section?.find(el => el.includes([tabPage]))).map(el => el.brand?.split(',')).flat()?.filter(el => el !== undefined)
      const categoryListFiltered = [...new Set(filterArray)]
      // console.log(categoryListFiltered);
      // console.log(brand);
      setBrandMapList(brand?.filter(el => categoryListFiltered.includes(el.brand)).sort((a, b) => (a.brand > b.brand) ? 1 : -1));
    }
  }, [allProductsData, brand, tabPage])


  const [mapData, setMapData] = useState()
  useEffect(() => {
    if (modPage == 'category') {
      setMapData(categoryMapList)
      console.log(categoryMapList);
    }
    if (modPage == 'brand') {
      setMapData(brandMapList)
      console.log(brandMapList);
    }
    if (modPage == 'businessType') {
      setMapData(bussinessMapList)
      console.log(bussinessMapList);
    }
  }, [categoryMapList, brandMapList, bussinessMapList, modPage])


  return (
    <div className='Preview'>
            <div className='PreviewAll'>
                <div className='PreviewAll__title' style={{ flexDirection: 'column', gap: '10px', width: '100%', alignItems: 'flex-start'}}>
                    {/* <img
                        src={iconFilter}
                        alt="="
                        width={50}
                        onClick={() => dispatch(setUiFilterModal(true))}
                    /> */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <div className='BarTab__title'>{tabPage}</div>
                      <div className='BarTab__btns'>
                        <div 
                          className='BarTab__btns__btn'
                          onClick={() => {dispatch(setUiFavoriteModal(true))}}
                        >
                          <img src={AddIcon} alt="" />
                          Favorits
                        </div>
                        <div 
                          className='BarTab__btns__btn'
                          onClick={() => dispatch(setUiFilterModal(true))}
                        >
                          <img src={SettingsIcon} alt="" />
                          Filtros
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex'}}>
                      <div 
                        className='tab__btn'
                        onClick={() => {navigate(`/home/${tabPage}`)}}
                      >Show the Bar</div>
                    </div>
                </div>
                <div className='PreviewAll__categories'>
                    {isLoading ? <CircularProgress /> : mapData?.map((item, index) => (
                        <div
                            key={item[modPage] + 'PrevAll' + index}
                            className='PreviewAll__categories__item'
                            onClick={() => handleGoToCategory(modPage, item[modPage])}
                        >
                            <img src={item[modPage + 'Image']} alt={item[modPage]} />
                            {item[modPage]}
                        </div>
                    ))}
                </div>
            </div>
        </div>
  )
}

export default AllTab