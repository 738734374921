import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  reqBodyData: {
    address: '',
    date: Date.now(),
    deliveryDate: '',
    deliveryPrice: 0,
    description: '',
    items: [],
    order: 'CP',
    paidValue: 0,
    paymentType: '',
    realType: '',
    status: {},
    tradeName: '',
    type: 'browser',
    userAcc: {}
  }
}


export const createOrderDataSlice = createSlice({
  name: 'reqBodyData',
  initialState,
  reducers: {
    setBody: (state = initialState, action) => {
      state.reqBodyData = action.payload
    },
    setItems(state = initialState, action) {
      state.reqBodyData.items = action.payload
    },
    setPaidValue(state = initialState, action) {
      state.reqBodyData.paidValue = action.payload
    },
    setPaidEmail(state = initialState, action) {
      state.reqBodyData.userAcc.email = action.payload
    },
    setPaidAddress(state = initialState, action) {
      state.reqBodyData.userAcc.billAddress = action.payload
    },
    setPaidImg(state = initialState, action) {
      state.reqBodyData.userAcc.img = action.payload
    },
    setPaidPhone(state = initialState, action) {
      state.reqBodyData.userAcc.phone = action.payload
    },
    setPaidName(state = initialState, action) {
      state.reqBodyData.userAcc.name = action.payload
    },
    setOrderAddress(state = initialState, action) {
      state.reqBodyData.address = action.payload
    },
    setDeliveryDate(state = initialState, action) {
      state.reqBodyData.deliveryDate = action.payload
    },
    setDeliveryPrice(state = initialState, action) {
      state.reqBodyData.deliveryPrice = action.payload
    },
    setDescription(state = initialState, action) {
      state.reqBodyData.description = action.payload
    },
    setPaymentType(state = initialState, action) {
      state.reqBodyData.paymentType = action.payload
    },
    setRealType(state = initialState, action) {
      state.reqBodyData.realType = action.payload
    },
    setStatus(state = initialState, action) {
      state.reqBodyData.status = action.payload
    },
    setTradeName(state = initialState, action) {
      state.reqBodyData.tradeName = action.payload
    }
  },
})

export const {
  setBody, 
  setItems, 
  setPaidValue, 
  setPaidEmail, 
  setPaidAddress,
  setPaidImg,
  setPaidPhone,
  setPaidName,
  setOrderAddress,
  setDeliveryDate,
  setDeliveryPrice,
  setDescription,
  setPaymentType,
  setRealType,
  setStatus,
  setTradeName,
} = createOrderDataSlice.actions

export default createOrderDataSlice.reducer
