import React, { useEffect, useState } from 'react'
import './OrdersPage.scss'
import { useSelector } from 'react-redux'
import OrderItem from '../../MainSection/OrderItem/OrderItem'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

const OrdersPage = () => {
	const [ordersTab, setOrdersTab] = useState('órdenes');
	const userAcc = useSelector((state) => state.accountData.userAcc)
    const [ordersData, setOrdersData] = useState({});
    const handleFetchData = async () => {
		const ordersUser = await axios.get(`https://tpomobi.shop/getOrdersAdminByTradeName?tradeName=${userAcc.tradeName}`)
		setOrdersData(ordersUser)
        console.log(ordersUser);
	}

	useEffect(() => {
		if (userAcc.tradeName !== '') {handleFetchData()}
	}, [userAcc.tradeName])


	return (
		<div className="Profile__mainPage__section__mainSection__item__ordersSection">
			<div className="Profile__mainPage__section__mainSection__item__ordersSection__header">
					<div
							onClick={() => setOrdersTab('órdenes')}   
							style={{backgroundColor: ordersTab === 'órdenes' && '#5FC56E'}}
							className="Profile__mainPage__section__mainSection__item__ordersSection__header__item"
					>
							órdenes actuales
					</div>
					<div
							onClick={() => setOrdersTab('Historia')}    
							style={{backgroundColor: ordersTab === 'Historia' && '#5FC56E'}}
							className="Profile__mainPage__section__mainSection__item__ordersSection__header__item"
					>
							Historia de pedidos
					</div>
			</div>
			{ordersData?.status == 200 ?
				<div className="Profile__mainPage__section__mainSection__item__ordersSection__content">
						{ordersTab === 'órdenes' && ordersData.data.map((item) => 
						item.status?.name?.toLowerCase() === 'emitido' && 
						<OrderItem item={item} key={'Order'+item._id}/>
							)}
						{ordersTab === 'Historia' && ordersData.data.map((item) => 
								<OrderItem item={item} idkey={item.id+item.name+'hist'} key={'Hist'+item.id}/>
						)}
				</div> : <CircularProgress/>}
		</div>
	)
}

export default OrdersPage