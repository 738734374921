import React from 'react'
import './HeaderBtn.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setOrderMobileTab } from '../../../../../../../store/slices/contextUI'


const HeaderBtn = ({children, tabName}) => {
	const dispatch = useDispatch();
	const mobileOrderTab = useSelector((state) => state.contextUI.orderPageMobile.ordersTab);
  return (
    <div 
			className='HeaderBtn'
			onClick={() => mobileOrderTab === tabName ? null : dispatch(setOrderMobileTab(tabName))}
			style={{borderBottom: mobileOrderTab === tabName ? '2px solid #5FC56E' : '2px solid transparent'}}
		>{children}</div>
  )
}

export default HeaderBtn