import React, { useState } from 'react'
import './CitchenTab.scss'
import { useNavigate, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { setUiFavoriteModal } from '../../../store/slices/contextUI'
import FreskoIcon from '../../components/assets/images/FreskoPageIcon.svg'
import CongeladorIcon from '../../components/assets/images/CongeladorPageIcon.svg'
import ProteinaIcon from '../../components/assets/images/ProteinaPageIcon.svg'

const CitchenTab = () => {
  const tabParams = useParams().tab
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [part, setPart] = useState('Congelador')

  return (
    <div className='KitchenTab'>
      <div className='KitchenTab__title'>
        {tabParams}
      </div>
      <div className='KitchenTab__content'>
        <div className='KitchenTab__content__header'>
          <div className='KitchenTab__content__header__settings'>
            <div 
              className='tab__btn'
              onClick={() => {navigate('/home/category/'+tabParams) }}
            >
              All for the chef
            </div>
          </div>
        </div>
        <div className='KitchenTab__content__part'>
          <div 
            className='KitchenTab__content__part__btn' 
            style={{ color: '#AAB7D9', border: part === 'Congelador' ? '3px solid #8ee164' : '2px solid #fff'}}
            onClick={() => setPart('Congelador')}  
          >
            Congelador
            <img src={CongeladorIcon} alt="" />
          </div>
          <div 
            className='KitchenTab__content__part__btn'
            style={{ color: '#FBD133', border: part === 'Proteina' ? '3px solid #8ee164' : '2px solid #fff'}}
            onClick={() => setPart('Proteina')}  
          >
            Proteína
            <img src={ProteinaIcon} alt="" />
          </div>
          <div 
            className='KitchenTab__content__part__btn'
            style={{ color: '#7EE16C', border: part === 'Fresko' ? '3px solid #8ee164' : '2px solid #fff'}}
            onClick={() => setPart('Fresko')}  
          >
            Fresco
            <img src={FreskoIcon} alt="" />
          </div>
        </div>
        <div className='KitchenTab__content__body' style={{ backgroundImage: `url(${part === 'Congelador' ? 'https://i.imgur.com/boJTAA4.png' : part === 'Proteina' ? 'https://i.imgur.com/W68yXv0.png' : 'https://i.imgur.com/Ttm1Ckv.png'})`}}>
          <div className='KitchenTab__content__body__row' style={{ justifyContent: 'center', top: '14%'}}>
            <div className='tab__btn'>
              Sale
            </div>
          </div>
          <div className='KitchenTab__content__body__row' style={{ justifyContent: 'center', top: '18%'}}>
            <div className='tab__btn'>
              New
            </div>
          </div>
          <div className='KitchenTab__content__body__row' style={{ justifyContent: 'center', top: '23%'}}>
            <div 
              className='tab__btn'
              onClick={() => {dispatch(setUiFavoriteModal(true))}}
            >
              Favorits
            </div>
          </div>
        </div>
        <div className='KitchenTab__content__footer'>
          <div 
            className='tab__btn'
            onClick={() => {navigate('/home/brand/'+tabParams) }}
          >
            Brand
          </div>
          <div 
            className='tab__btn'
            onClick={() => {navigate('/home/businessType/'+tabParams) }}
          >
            Cuisine
          </div>
        </div>
      </div>
    </div>
  )
}

export default CitchenTab