import React from 'react'
import './AdditionalInfo.scss'

import { Box, Typography, useMediaQuery } from '@mui/material'
import StyledBox from '../../../StyledBox/StyledBox'
import { useTranslation } from 'react-i18next'

export const AdditionalInfo = ({ contact }) => {
  const mobileView = useMediaQuery('(max-width: 798px)')
  const { t } = useTranslation()
  return (
    <StyledBox
      headerText={'Datos de la empresa'}
      defaultVisible={mobileView ? false : true}
      className='additionalInfo'
      sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
    >
      {/* <Box sx={{ minHeight: '26px' }} className='mainInfo__title'></Box> */}
      <Box className='additionalInfo__content'>
        {/* {mobileView ? (
          <Box className='additionalInfo__content__item'>
            <Typography className='additionalInfo__content__item__title'>ID:</Typography>
            <Typography className='additionalInfo__content__item__text'>{contact?._id ? contact?._id : ''}</Typography>
          </Box>
        ) : null} */}
        {mobileView ? (
          <Box className='additionalInfo__content__item'>
            <Typography className='additionalInfo__content__item__title'>{t('StaticPage.mainInfo.code')}:</Typography>
            <Typography className='additionalInfo__content__item__text'>{contact?.tradeName ? contact?.tradeName : ''}</Typography>
          </Box>
        ) : null}
        {mobileView ? (
          <Box className='additionalInfo__content__item'>
            <Typography className='additionalInfo__content__item__title'>{t('StaticPage.mainInfo.name')}:</Typography>
            <Typography className='additionalInfo__content__item__text'>{contact?.name ? contact?.name : ''}</Typography>
          </Box>
        ) : null}
        {mobileView ? (
          <Box className='additionalInfo__content__item'>
            <Typography className='additionalInfo__content__item__title'>{t('StaticPage.mainInfo.employee')}:</Typography>
            <Typography className='additionalInfo__content__item__text'>{contact?.employee ? contact?.employee : ''}</Typography>
          </Box>
        ) : null}
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.phone')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>+{contact?.phone ? contact?.phone : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.iban')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.iban ? contact?.iban : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.swift')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.swift ? contact?.swift : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.nif')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.nif ? contact?.nif : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.cif')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.cif ? contact?.cif : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.email')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.email ? contact?.email : ''}</Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.address')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>
            {contact?.billAddress?.address ? contact?.billAddress?.address : ''}
          </Typography>
        </Box>
        <Box className='additionalInfo__content__item'>
          <Typography className='additionalInfo__content__item__title'>{t('StaticPage.additionalInfo.city')}:</Typography>
          <Typography className='additionalInfo__content__item__text'>{contact?.billAddress?.city ? contact?.billAddress?.city : ''}</Typography>
        </Box>
      </Box>
    </StyledBox>
  )
}
