const unitEqual = [
{sku: 'BFruAlbarikg', name: 'Albaricoques kg',	und: 0.170},		
{sku: 'BFruCaquikg', name: 'Caqui kg', und:	0.250},
{sku: 'BFruCarambkg', name: 'Carambola kg', und: 0.100},
{sku: 'BFruGranaKg', name: 'Granada kg', und: 0.330},
{sku: 'BFruKiwikg', name: 'Kiwi kg', und: 0.130},
{sku: 'BFruKiwiZespkg', name: 'Kiwi Zespri kg', und: 0.130},
{sku: 'LimaCat1KG', name: 'Lima (cat 1) kg', und: 0.100,	caja: 4.5},
{sku: 'LimaCat2KG', name: 'Lima (cat 2) kg',	und: 0.100,	caja: 4.5},
{sku: 'BFruLiKafkg', name: 'Lima Kaffir kg', und: 0.80},
{sku: 'BLEMEkg', name: 'Limón para Mesa kg', und: 0.170, caja: 10},
{sku: 'BLEZUkg', name: 'Limón para Zumo kg', und: 0.170, caja: 10},
{sku: 'BFruMandarinakg', name: 'Mandarina kg', und: 0.150},
{sku: 'BFruMangAVkg', name: 'Mango de Avion kg',	und: 0.500},
{sku: 'BFruMangoDURkg', name: 'Mango Normal kg',	und: 0.500},
{sku: 'BFruManzFugiKG', name: 'Manzana Fuji kg',	und: 0.200},
{sku: 'BFruManzGolKG', name: 'Manzana Golden kg',	und: 0.200},
{sku: 'BFruManzGrSmiKG', name: 'Manzana Granny Smith', und:	0.200},
{sku: 'BFruManzRojKG', name: 'Manzana Roja kg',	und: 0.200},
{sku: 'BFruMarackg', name: 'Maracuya (pasion fruit) kg', und: 0.80},
{sku: 'BFruMaracPremkg', name: 'Maracuya (pasion fruit) Premium kg', und: 0.80},
{sku: 'BFruMelocotonkg', name: 'Melocotón kg', und: 0.120},
{sku: 'BFruMelocNectkg', name: 'Melocotón Nectarina kg', und: 0.100},
{sku: 'BFruMelocParkg', name: 'Melocotón Paraguayo kg', und: 0.80},
{sku: 'BFruMelonCankg', name: 'Melón Cantalupo kg', und: 1.200},
{sku: 'BFruMELONKg', name: 'Melón kg', und: 3},
{sku: 'FruMelSapkg', name: 'Melón Sapo kg', und: 3},
{sku: 'NarExtraKG', name: 'Naranja Extra (para piel) kg', und: 0.350},
{sku: 'BNarMesKG', name: 'Naranja para Mesa kg', und: 0.350},
{sku: 'BNarZumKG', name: 'Naranja para Zumo kg', und: 0.200},
{sku: 'BNarSangKG', name: 'Naranja Sanguina kg',	und: 0.170},
{sku: 'BFruPapayakg', name: 'Papaya kg',	und: 1.200},
{sku: 'BFruPeraConfkg', name: 'Pera Conferencia kg',	und: 0.200},
{sku: 'BFruPerakg', name: 'Pera kg',	und: 0.150},
{sku: 'BFruPinaKG', name: 'Piña kg',	und: 2},
{sku: 'BFruPlatCanKg', name: 'Plátano Canario kg', und: 0.150},
{sku: 'BFruPlaMachkg', name: 'Plátano Macho (Africano) kg',	und: 0.250},
{sku: 'BFruPlataKG', name: 'Plátano Normal (Banana) kg', und: 0.170},
{sku: 'BFruPomelKG', name: 'Pomelo kg',	und: 0.330},
{sku: 'BFruSandiKg', name: 'Sandía Rayada kg',	und: 4.5},
{sku: 'FruSandiaSinPepKG', name: 'Sandía sin Pepitas kg',	und: 4.5},
{sku: 'AAGHkg', name: 'Aguacate Hass kg', und:	0.200, caja: 4},
{sku: 'AAGEttinKG', name: 'Aguacate Verde Liso kg', und: 0.300},
{sku: 'BVerAjokg', name: 'Ajo Seco kg', und: 0.100},
{sku: 'BVerAlcachofakg', name: 'Alcachofas kg', und: 0.300},
{sku: 'BVerBerenjenakg', name: 'Berenjena kg', und: 0.250},
{sku: 'BVerBONIAKg', name: 'Boniato kg', und: 0.330},
{sku: 'BVerCalabacinkg', name: 'Calabacín kg', und: 0.250},
{sku: 'BVerCalHOKkg', name: 'Calabaza Hokkaido kg', und: 1.500},
{sku: 'BVerCalabazakg', name: 'Calabaza kg',	und: 3},
{sku: 'BVerCalVioKG', name: 'Calabaza Violin kg', und: 2.5},
{sku: 'BCebBlakg', name: 'Cebolla Gorda Blanca kg',	saco: 25},
{sku: 'BCebMorkg', name: 'Cebolla Morada (Roja) kg', caja: 10},
{sku: 'BVerColChinkg', name: 'Col China (Napa) kg',	und: 1.5},
{sku: 'BVerColLOMBKG', name: 'Col Lombarda (Morada) kg', und: 2},
{sku: 'BVerColREPkg', name: 'Col Repollo Valenciana (Blanca) kg', und: 2},
{sku: 'BVerDaikoKg', name: 'Daikon kg', und: 1},
{sku: 'BVerHinFrekg', name: 'Hinojo Fresco kg', und: 0.250},
{sku: 'BVerJengkg', name: 'Jengibre Fresco kg', und: 0.200},
{sku: 'DORMaizFrkg', name: 'Maíz Fresco kg', und: 0.200},
{sku: 'BPatAGRkg', name: 'Patata Agria kg',	saco: 25},
{sku: 'BPatMONkg', name: 'Patata Monalisa Agata kg', saco: 15},
{sku: 'BVerPEPHolKg', name: 'Pepino Holandes kg', und: 0.300},
{sku: 'BVerPepinKG', name: 'Pepino kg', und: 0.250},
{sku: 'BPimAmaKG', name: 'Pimiento Dulce Amarillo kg', und: 0.330},
{sku: 'BPimRojKG', name: 'Pimiento Dulce Rojo kg', und: 0.330},
{sku: 'BPimVerKG', name: 'Pimiento Dulce Verde kg', und: 0.330},
{sku: 'BPimITAkg', name: 'Pimiento Italiano kg', und: 0.250},
{sku: 'VerPuerroKG', name: 'Puerro kg',	und: 0.330},
{sku: 'BVerRemolakg', name: 'Remolacha kg',	und: 0.150},
{sku: 'VerTomCorBuekg', name: 'Tomate Corazon de Buey kg',	und: 0.250},
{sku: 'BTomDanEnsKG', name: 'Tomate Daniela Ensalada kg', und: 0.170},
{sku: 'BTomKumkg', name: 'Tomate Kumato kg', und: 0.140},
{sku: 'BTomPerkg', name: 'Tomate Pera kg', und: 0.140},
{sku: 'BTomRamkg', name: 'Tomate Rama kg', und: 0.140},
{sku: 'BVerYucakg', name: 'Yuca (Mandioca) kg', und: 0.250},
{sku: 'BVerZanahkg', name: 'Zanahoria kg', und: 0.250},
{sku: 'FruGuayImportKg', name: 'Guayaba Importación kg', und: 0.170},
{sku: 'BFruGuayaKg', name: 'Guayaba Nacional kg',	und: 0.170},
{sku: 'BFruCIRUEKg', name: 'Ciruela kg', und: 0.130},
]

export default unitEqual