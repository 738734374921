import React from 'react'
import './ProfileMobile.scss'
import DarkArrowLeft from '../../../assets/images/DarkArrowLeft.svg'
import { useNavigate } from 'react-router'
import { Autocomplete, TextField } from '@mui/material'


const ProfileMobile = () => {
  const navigate = useNavigate();
  
  return (
    <div className='ProfileMobilePage'>
      <div className='ProfileMobileDatos'>
        <div
          className='ProfileMobileDatos__back'
          onClick={() => navigate('/profile/home')}
        ><img src={DarkArrowLeft} alt="" /></div>
        <div className="Profile__mainPage__section__mainSection__item__language" style={{margin: 'auto', marginBottom: '500px'}}>
            <span>Chose the language</span>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={['Ru', 'En', 'Fr', 'Es']}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          </div>
      </div>
    </div>
  )
}

export default ProfileMobile