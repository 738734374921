import React, { useEffect } from 'react'
import './ProfilePlate.scss'
import { useSelector } from 'react-redux'
import ImgChekbox from '../../../assets/images/pencil_checkmark-filled.svg'
import LettersIcon from '../../../assets/images/letters.svg'
import BonusIcon from '../..//../assets/images/bonus.png'


const ProfilePlate = () => {
  const userAcc = useSelector((state) => state.accountData.userAcc);
  
  return (
    <div className="ProfilePlate">
        <div className="ProfilePlate__img">
            <img src={userAcc.img} alt="" />
            <span><img src={ImgChekbox} alt=""/></span>
        </div>
        <div className="ProfilePlate__info">
            <div className="ProfilePlate__name">
                {userAcc.name}
            </div>
            <div className="ProfilePlate__username">
                User name:  <span>{userAcc.username}</span>
            </div>
            {userAcc.ambassador && 
            <div className="ProfilePlate__ambassador">
                Ambassador
            </div>}
            <div className="ProfilePlate__id">
                ID: <span>{userAcc._id}</span>
            </div>
        </div>
        {/* <div className="ProfilePlate__bonus">
            <img src={BonusIcon} alt="" />
            <div className="ProfilePlate__bonus__info">
                Bono : 105€
            </div>
        </div> */}
        <div className="ProfilePlate__letters">
            <span>Letters of happiness</span>
            <img src={LettersIcon} alt="" />
            <div className="ProfilePlate__ambassador">Open</div>
        </div>
    </div>
  )
}

export default ProfilePlate