import React, { useEffect } from 'react'
import Profile from '../../assets/images/Profile.svg'
import './LoginButton.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setAccModal } from '../../../../store/slices/accModal'
import axios from 'axios'
import useSendLog from '../../../../hooks/useSendLog'
const LoginButton = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tradeNameParams = useParams().tradeName
  const handleGoToLogin = () => {
    dispatch(setAccModal('Login'))
  }
  const handleGoToProfile = () => {
    navigate('/profile/home')
  }

  const isLogin = useSelector((state) => state.contextUI.isLogin)
  

  const handleGoToPedido = useSendLog('Click on mis pedido button', {});

  
  return (tradeNameParams ? 
    <div 
      className='login__btn'
      onClick={() => {
        navigate('/account/' + tradeNameParams)
        handleGoToPedido()
      }}
    >
      <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='black' d='m17.578 4.432l-2-1.05C13.822 2.461 12.944 2 12 2s-1.822.46-3.578 1.382l-.321.169l8.923 5.099l4.016-2.01c-.646-.732-1.688-1.279-3.462-2.21m4.17 3.534l-3.998 2V13a.75.75 0 0 1-1.5 0v-2.286l-3.5 1.75v9.44c.718-.179 1.535-.607 2.828-1.286l2-1.05c2.151-1.129 3.227-1.693 3.825-2.708c.597-1.014.597-2.277.597-4.8v-.117c0-1.893 0-3.076-.252-3.978M11.25 21.904v-9.44l-8.998-4.5C2 8.866 2 10.05 2 11.941v.117c0 2.525 0 3.788.597 4.802c.598 1.015 1.674 1.58 3.825 2.709l2 1.049c1.293.679 2.11 1.107 2.828 1.286M2.96 6.641l9.04 4.52l3.411-1.705l-8.886-5.078l-.103.054c-1.773.93-2.816 1.477-3.462 2.21'/%3E%3C/svg%3E" alt="" width={30}/>
      Mis pedidos
    </div> :
    <div className='login__box'>
      {!isLogin ? (
        <div
          className='login__btn'

          onClick={handleGoToLogin}>
        <img src={Profile} alt='' />
        Acceder
      </div>
      ) : (
        <div
          className='login__btn'
          onClick={handleGoToProfile}>
        <img src={Profile} alt='' />
        My account
      </div>
      )}
    </div>
  )
}

export default LoginButton
