import React from 'react'
import './PreviewNavMobile.scss'
import PreviewMenu from '../PreviewMenu/PreviewMenu'
import PreviewMoreTabs from '../PreviewMoreTabs/PreviewMoreTabs'
import SearchBar from '../../../SearchBar/SearchBar'

const PreviewNavMobile = () => {
  return (
    <div className='previewNavMobile'>
        <PreviewMoreTabs/>
          <SearchBar/>
        <PreviewMenu/>
    </div>
  )
}

export default PreviewNavMobile