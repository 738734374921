import React from 'react'
import './PreviewMoreTabs.scss'
import MoreTabsIcon from '../../../../assets/images/MoreSideBar.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setMoreTab } from '../../../../../../store/slices/contextUI'
import MoreTabSideBar from '../../../../ModalWindows/MoreTabSideBar/MoreTabSideBar'

const PreviewMoreTabs = () => {
  const dispatch = useDispatch()
	const MoreTabOpen = useSelector(state => state.contextUI.previewNav.openMoreTab)
	
  return (
    <div className='PreviewMoreTabs'>
        <img src={MoreTabsIcon} onClick={() => {dispatch(setMoreTab(!MoreTabOpen))}}/>
				{MoreTabOpen && <MoreTabSideBar/>}
    </div>
  )
}

export default PreviewMoreTabs