import React, { useEffect, useState } from 'react'
import './OrderPageMobile.scss'
import HeaderBtn from './HeaderBtn/HeaderBtn'
import OrderItemMobile from './OrderItemMobile/OrderItemMobile'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import axios from 'axios';
import DarkArrowLeft from '../../../../assets/images/DarkArrowLeft.svg'
import { useNavigate } from 'react-router'


const OrderPageMobile = () => {
	const navigate = useNavigate();
	const orderTab = useSelector(state => state.contextUI.orderPageMobile.ordersTab)

	const userAcc = useSelector((state) => state.accountData.userAcc)
    const [ordersData, setOrdersData] = useState({});
    const handleFetchData = async () => {
		const ordersUser = await axios.get(`https://tpomobi.shop/getOrdersAdminByTradeName?tradeName=${userAcc.tradeName}`)
		setOrdersData(ordersUser)
	}

	useEffect(() => {
		if (userAcc.tradeName !== '') {handleFetchData()}
	}, [userAcc.tradeName])

  return (
		<div className='ProfileMobileDatos'>
			<div
				className='ProfileMobileDatos__back'
				onClick={() => navigate('/profile/home')}
			><img src={DarkArrowLeft} alt="" /></div>
			<div className='ProfileMobileDatos__section'>
				<div className='OrderPageMobile'>
					<div className='OrderPageMobile__header'>
						<HeaderBtn tabName={'Todo'}>órdenes actuales</HeaderBtn>
						<HeaderBtn tabName={'History'}>Historia de pedidos</HeaderBtn>
					</div>
					{ordersData?.status == 200 ?
					<div className='OrderPageMobile__body'>
						{orderTab == 'Todo' && ordersData.data.map((item) => 
							item.status?.name?.toLowerCase() === 'emitido' && 
							<OrderItemMobile item={item}/>
						)}

						{orderTab == 'History' && ordersData.data.map((item) => 
						<OrderItemMobile item={item}/>
						)}
					</div> : <CircularProgress/>}
				</div>
			</div>
		</div>
  )
}

export default OrderPageMobile