import React from 'react'
import './ProfileHome.scss'
import ProfileSideBar from '../SideBar/ProfileSideBar';
import ProfilePlate from '../ProfilePlate/ProfilePlate';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';


const ProfileHome = () => {
  const userAcc = useSelector((state) => state.accountData.userAcc)
  const [ordersData, setOrdersData] = useState({});
  const handleFetchData = async () => {
		const ordersUser = await axios.get(`https://tpomobi.shop/getOrdersAdminByTradeName?tradeName=${userAcc.tradeName}`)
		setOrdersData(ordersUser)
	}

	useEffect(() => {
		if (userAcc.tradeName !== '') {handleFetchData()}
	}, [userAcc.tradeName])

  return (
    <div className='ProfileHome__container'>
      <div className='ProfileHome__container__section'>
        <div className='ProfileHome__container__section__item__left'>
          <ProfileSideBar/>
        </div>
        <div className='ProfileHome__container__section__item__right'>
          <div className="Profile__mainPage__section__mainSection__item__profile">
                <ProfilePlate/>
            </div>
            <div className="Profile__mainPage__section__mainSection__item__profile">
                Pedidos totales
                <div className="Profile__mainPage__section__mainSection__item__profile__count">
                    {ordersData?.data?.length || 0}
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHome