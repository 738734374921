import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allItems: [],
}

export const allItemsSlice = createSlice({
  name: 'allItems',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.allItems = [...action.payload]
    },
  },
})

export const { setItems } =
  allItemsSlice.actions
export default allItemsSlice.reducer
