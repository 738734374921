import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mainApi } from '../../store/services/mainApi'
import ContentCard from '../components/Content/ContentCard/ContentCard'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterCategory, setFilterSpecialization } from '../../store/slices/filterData'

const SpecializationCategoryPage = () => {
  const dispatch = useDispatch()
  const { specializationName, categoryName } = useParams()
  const [mapItems, setMapItems] = useState([]);

  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })

  useEffect(() => {
    if (!isLoading) {
      setMapItems(allProductsData?.filter(el => el.specialization === specializationName && el.category === categoryName))
    }
  }, [allProductsData])

  const filters = useSelector((state) => state.filterData.filterData.filters)
  useEffect(() => {
    dispatch(setFilterSpecialization([specializationName]))
    dispatch(setFilterCategory([categoryName]))
  }, [])

  useEffect(() => {
    if (!isLoading) {
      let filteredProducts = allProductsData?.filter(product =>
        product.enabled == true 
        && (filters.category.length > 0 ? product.category.includes(filters.category) : true)
        && (filters.specialization.length > 0 ? product.specialization.includes(filters.specialization) : true)
        && (filters.section.length > 0 ? product.section.find((el) => el.includes(filters.section)) : true)
      );
      filteredProducts = filteredProducts.sort(function(a, b) {if (a.name < b.name) return -1})
      setMapItems(filteredProducts);
      console.log(filteredProducts);
    }
  }, [allProductsData, filters, isLoading])

  return (
    <div className='categoriesMain'>
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{categoryName}</div>
      </div>
      <div className='categoriesPage'>
        {!isLoading && mapItems.map((item) => (
          <ContentCard card={item} key={item._id} />
        ))}
      </div>
    </div>
  )
}

export default SpecializationCategoryPage