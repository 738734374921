import React from 'react'
import './CartElemInfoModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SuccessStock } from '../../assets/images/SuccessStock.svg'
import { ReactComponent as OutOfStock } from '../../assets/images/DeniedStock.svg'
import { ReactComponent as StockTBC } from '../../assets/images/StockTBC.svg'
import { ReactComponent as SubscribeStock } from '../../assets/images/SubscribeSTOCK.svg'
import { ReactComponent as WeightStock } from '../../assets/images/WeigthSTOCK.svg'


const CartElemInfoModal = () => {
  const openModalCartElemInfo = useSelector(state => state.contextUI.cartElemInfo)

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center'}}>
      {openModalCartElemInfo ? <div className='CartElemInfoModal'>
      <div className='CartElemInfoModal__item'>
        <div
          style={{border: '1px solid #5fc56e', color: '#5fc56e'}}
          className='contentCard__body__subtitle__info stock'>
          <SuccessStock className='contentCard__body__subtitle__info__svg' />
          Stock
        </div>
        - Disponible en stock
      </div>
      <div className='CartElemInfoModal__item'>
        <div 
          style={{border: '1px solid #f22f2f', color: '#f22f2f'}}
          className='contentCard__body__subtitle__info outOfStock'>
          <OutOfStock className='contentCard__body__subtitle__info__svg' />
          Stock
        </div>
        - No esta disponible 
      </div>
      <div className='CartElemInfoModal__item'>
        <div 
          style={{border: '1px solid #ffc727', color: '#ffc727'}}
          className='contentCard__body__subtitle__info TBC'>
          <StockTBC className='contentCard__body__subtitle__info__svg' />
          Confirmar
        </div>
        - Pendiente de confirmación 
      </div>
      <div className='CartElemInfoModal__item'>
        <div
          style={{border: '1px solid #407bff', color: '#407bff'}}
          className='contentCard__body__subtitle__info dias'
        >2 Days
        </div>
        - Días para la producción
      </div>
      <div className='CartElemInfoModal__item'>
        <div
          style={{border: '1px solid #5fc56e'}}
          className='contentCard__body__subtitle__info stock'>
          <SubscribeStock className='contentCard__body__subtitle__info__svg' />
        </div>
        - Subscribe for notification
      </div>
      <div className='CartElemInfoModal__item'>
      <div
          style={{border: '1px solid #5fc56e', backgroundColor: '#5fc56e', color: '#fff'}}
          className='contentCard__body__subtitle__info stock'>
          <WeightStock className='contentCard__body__subtitle__info__svg' />
        </div>
        - El precio final será actualizado por el peso del producto
      </div>
      <div className='CartElemInfoModal__item'>
        <div 
          style={{border: '1px solid #ffc727', color: '#ffc727'}}
          className='contentCard__body__subtitle__info TBC'>
          SPO
        </div>
        - Solo Ofertas Especiales
      </div>
    </div> : <></>}
    </div>
  )
}

export default CartElemInfoModal