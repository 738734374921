import React, { useEffect, useState } from 'react'
import './AutoLogin.scss'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setUserAcc } from '../../../../../store/slices/accountData'
import { setIsLogin } from '../../../../../store/slices/contextUI'

const AutoLogin = () => {
  const userAcc = useSelector((state) => state.accountData.userAcc);
  const [responseUser, setResponseUser] = useState(userAcc);
  const dispatch = useDispatch()
  // useEffect(() => {
  //   console.log(userAcc);
  // }, [userAcc])


  const initLogin = async () => {
    if (localStorage.getItem('LoginData') !== null && localStorage.getItem('LoginRules') !== null) {
    const currentDate = new Date();
    const refreshLoginDate = JSON.parse(localStorage.getItem('LoginRules'))
    if (currentDate > refreshLoginDate.refreshLogin) {
      localStorage.removeItem('LoginData')
      localStorage.removeItem('LoginRules')
    } else {
    const LoginData = JSON.parse(localStorage.getItem('LoginData'))
    
    let informData =  {
        phone: LoginData.phoneLogin,
        password: LoginData.passwordLogin,
    };
    const responseLogin = await axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
    dispatch(setUserAcc(responseLogin.data))
    dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
    // dispatch(setAccPhone(responseLogin.data.phone))
    // dispatch(setAccName(responseLogin.data.name))
    // dispatch(setAccIsLogined(responseLogin.data !== '' ? true : false))
    // console.log(responseLogin.data);
    
    
  } }
}
  
  useEffect(() => {
    initLogin()
  }, [window.localStorage])

}

export default AutoLogin