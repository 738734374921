import React, { useState, useEffect, useMemo } from 'react'
import { mainApi } from '../../../../../store/services/mainApi'
import { CircularProgress } from '@mui/material'
import './PreviewAll.scss'
import iconFilter from '../../../assets/images/icon_filter.svg'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setUiFilterModal } from '../../../../../store/slices/contextUI'
import { useParams } from "react-router-dom";

const PreviewAll = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
        pollingInterval: 50000,
      })
    const { data: category, isLoading: isLoadingCategory } = mainApi.useGetCategoryListQuery()
    const { data: brand, isLoading: isLoadingBrand } = mainApi.useGetBrandsListQuery()
    const { data: businessType, isLoading: isLoadingBusiness } = mainApi.useGetBusinessTypeListQuery()
    const { categoryType } = useParams()
    const handleGoToCategory = (searchType, category) => {
        navigate(`/categories/${searchType}/${category}`)
    }

    const filters = useSelector((state) => state.filterData.filterData.filters)
   

    const [categoryMapList, setCategoryMapList] = useState([])
    
    useEffect(() => {
    if (!isLoadingCategory && !isLoading) {
        let filterArray = allProductsData?.filter(product => product.section?.find(el => el.includes(filters.section))).map(el => el.category?.split(','))
        const categoryListFiltered = [...new Set(filterArray)]
        console.log(category?.filter(el => categoryListFiltered.includes(el.category)))
        
        setCategoryMapList(categoryListFiltered);
    }
    }, [allProductsData, category, filters])

    const categoryData = useMemo(() => {
        switch (categoryType) {
            case "category":
                return category;
            case "brand":
                return brand;
            case "businessType":
                return businessType;
            // Add more cases if needed
            default:
                return [];
        }
    }, [categoryMapList, brand, businessType]);


    return (
        <div className='Preview'>
            <div className='PreviewAll'>
                <div className='PreviewAll__title'>
                    {/* <img
                        src={iconFilter}
                        alt="="
                        width={50}
                        onClick={() => dispatch(setUiFilterModal(true))}
                    /> */}
                    {categoryType == 'category' && 'Categories'}
                    {categoryType == 'brand' && 'Brands'}
                    {categoryType == 'businessType' && 'Business Types'}
                </div>
                <div className='PreviewAll__categories'>
                    {isLoading ? <CircularProgress /> : categoryData.map((item, index) => (
                        <div
                            key={item[categoryType] + 'PrevAll' + index}
                            className='PreviewAll__categories__item'
                            onClick={() => handleGoToCategory(categoryType, item[categoryType])}
                        >
                            <img src={item[categoryType + 'Image']} alt={item[categoryType]} />
                            {item[categoryType]}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PreviewAll
