import BarIcon from '../pages/components/assets/images/BarIcon.svg'
import NeveraIcon from '../pages/components/assets/images/NeveraIcon.svg'
import AlmacenIcon from '../pages/components/assets/images/AlmacenIcon.svg'
import FrescoIcon from '../pages/components/assets/images/FreskoIcon.svg'
import CongeladorIcon from '../pages/components/assets/images/CongeladorIcon.svg'
import buttonsCategories from './categoryButtons'
import buttonsCategoriesSlider from './categoryButtonsSilder'


const buttonsCategoriesFilter = [
    {
        items: buttonsCategories,
        name: 'Section',
        buttons: [],
    },
    {
        items: [],
        name: 'Categories',
        buttons: [],
    },
    {
        items: [],
        name: 'Cuisine',
        buttons: [
            // {
            //     icon: FrescoIcon,
            //     img: 'https://i.imgur.com/e1hF0sV.png',
            //     bigImg: 'https://i.imgur.com/LjJTyo1.png',
            //     name: 'Fruit & Vegetables'
            // },
            // {
            //     icon: NeveraIcon,
            //     img: 'https://i.imgur.com/8tPmkfV.png',
            //     bigImg: 'https://i.imgur.com/fFcLNnA.png',
            //     name: 'Proteína'
            // },
        ],
    },
    {
        items: [],
        name: 'Brand',
        buttons: [],
    },
    {
        items: buttonsCategoriesSlider,
        name: 'Specialization',
        buttons: [],
    },
]

export default buttonsCategoriesFilter