import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accModal: {
    Modal: '',
    Profile: false,
  }
}


export const accModalSlice = createSlice({
  name: 'accModal',
  initialState,
  reducers: {
    setBody: (state = initialState, action) => {
      state.accModal = action.payload
    },
    setAccModal(state = initialState, action) {
      state.accModal.Modal = action.payload
    },
    setProfileModal(state = initialState, action) {
      state.accModal.Profile = action.payload
    },
  },
})

export const {
  setBody, 
  setAccModal,
  setProfileModal,
} = accModalSlice.actions

export default accModalSlice.reducer
