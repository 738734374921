import React, { useEffect, useState } from 'react'
import './MoreTabSideBar.scss'
import WhiteEarthIcon from '../../assets/images/WhiteEarthLine.svg'
import WhiteArrowDown from '../../assets/images/WhiteArrowDown.svg'
import WhiteHelpIcon from '../../assets/images/WhiteHelpIcon.svg'
import TelegramIcon from '../../assets/images/logos_telegram.svg'
import WhatsappIcon from '../../assets/images/WhatsupIconLogin.svg'
import WhiteCloseIcon from '../../assets/images/WhiteCloseIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setMoreTab } from '../../../../store/slices/contextUI'
import { useNavigate, useParams } from 'react-router'
import { setCategoryButtonsTab } from '../../../../store/slices/contextUI'
import { mainApi } from '../../../../store/services/mainApi'
import buttonsCategoriesFilter from '../../../../constants/categoryButtonsFilter'
import buttonsCategories from '../../../../constants/categoryButtons'
import { setFilterCategory, setFiltersSection } from '../../../../store/slices/filterData'


const MoreTabSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterBtn, setFilterBtn] = useState(buttonsCategoriesFilter);
  const filters = useSelector((state) => state.filterData.filterData.filters)
  const tradeNameParams = useParams().tradeName

  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const allSectionProducts = allProductsData?.filter(product => product.section?.find(el => filters.section.includes(el)))

  const [openTab, setOpenTab] = useState('');

  const [filterdeCategoryList, setFilterdeCategoryList] = useState([])
  const { data: CategoryList, isLoading: isLoadingCategory } = mainApi.useGetCategoryListQuery()
  useEffect(() => {
    if (!isLoading) {
      const filterProducts = allProductsData?.map(el => el.category?.split(',')).flat()
      const filterCategory = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
      // console.log(CategoryList);
      // console.log(filterCategory);
      setFilterdeCategoryList(CategoryList?.filter(el => (filterCategory.includes(el.category))));
    } 
  }, [CategoryList, filters, isLoading])
  
  const [filterdeBusinessTypeList, setFilterdeBusinessTypeList] = useState([])
  const { data: BusinessTypeList, isLoading: isLoadingBusiness } = mainApi.useGetBusinessTypeListQuery()
  useEffect(() => {
    if (!isLoading) {
      const filterProducts = allProductsData?.map(el => el.businessType?.split(',')).flat()
      const filterBusinessType = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
      // console.log(BusinessTypeList);
      // console.log(filterBusinessType);
      setFilterdeBusinessTypeList(BusinessTypeList?.filter(el => (filterBusinessType.includes(el.businessType))));
    } 
  }, [BusinessTypeList, filters, isLoading])
  
  const { data: brandsList, isLoading: isLoadingBrands } = mainApi.useGetBrandsListQuery()
  const [filterBrandsList, setFilterBrandsList] = useState([])
  useEffect(() => {
      if (!isLoading) {
        const filterProducts = allProductsData?.map(el => el.brand?.split(',').map(el => el?.replace('Marca ', ''))).flat()
        const filterBrandList = Array.from(new Set(filterProducts))?.filter(item => item !== null && item !== undefined)
        // console.log(brandsList);
        // console.log(filterBrandList);
        setFilterBrandsList(brandsList?.filter(el => (filterBrandList.includes(el.brand))));
      } 
  }, [brandsList, filters, isLoading])


  //Beta Functional
  const [specializationList, setSpecializationList] = useState([])
  useEffect(() => {
      if (!isLoading) {
      const allFilterdata = allProductsData.map(el => el.specialization)
      const specializationList = Array.from(new Set(allFilterdata))
      setSpecializationList(specializationList)}
    }, [allProductsData, filters])

  return (
    <div className='MoreTabSideBar'>
      <div className='MoreTabSideBar__sidebar'>
        <div className='MoreTabSideBar__sidebar__close'>
          <img src={WhiteCloseIcon} alt="X" onClick={() => dispatch(setMoreTab(false))}/>
        </div>
        <div className='MoreTabSideBar__sidebar__tabs'>
          {/* {filterBtn.map((item, index) => (
            <div 
              className='MoreTabSideBar__sidebar__tabs__container'
              key={'MoreTabSideBar__sidebar__tabs__container-'+item.name}
            >
              <button
                className='MoreTabSideBar__sidebar__tabs__container__item'
                onClick={() => {setOpenTab(openTab === item.name ? '' : item.name); }}
                // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
                style={{ borderBottom: openTab === item.name ? '1px solid #5FC56E' : 'transparent' }}
              >
                {item.name}
              </button>
              {openTab === item.name &&
              <div 
                className='MoreTabSideBar__sidebar__tabs__container__options'>
                {item.items.map((el) => (
                  <div 
                    className='MoreTabSideBar__sidebar__tabs__container__options__item'
                    key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+el.name}
                    onClick={() => {item.name == 'Section' ? navigate('/home') : navigate('/categories/category/'+el.name);
                    item.name == 'Section' && handleClick(el);
                    dispatch(setMoreTab(false))}}
                  >
                    <img src={el.icon} alt="" />
                    {el.name}
                  </div>
                ))}
              </div>}
            </div>
          ))} */}

          <div 
            className='MoreTabSideBar__sidebar__tabs__container'
          >
            <button
              className='MoreTabSideBar__sidebar__tabs__container__item'
              onClick={() => {setOpenTab(openTab === 'Section' ? '' : 'Section'); }}
              // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
              style={{ borderBottom: openTab === 'Section' ? '1px solid #5FC56E' : 'transparent' }}
            >
              Sectores
            </button>
            {openTab === 'Section' &&
            <div 
              className='MoreTabSideBar__sidebar__tabs__container__options'>
              {buttonsCategories.map((item) => (
                <div 
                  className='MoreTabSideBar__sidebar__tabs__container__options__item'
                  key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+item.name}
                  onClick={() => {navigate('/home');
                  dispatch(setFiltersSection([item.name]))
                  // dispatch(setCategoryButtonsTab(buttonsCategories.find((el) => el.name === item.name)))
                  dispatch(setMoreTab(false))
                  navigate('/home/'+item.tab)
                }}
                >
                  <img src={item.icon} alt="" />
                  {item.name}
                </div>
              ))}
            </div>}
          </div>
          <div 
            className='MoreTabSideBar__sidebar__tabs__container'
          >
            <button
              className='MoreTabSideBar__sidebar__tabs__container__item'
              onClick={() => {setOpenTab(openTab === 'Categories' ? '' : 'Categories'); }}
              // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
              style={{ borderBottom: openTab === 'Categories' ? '1px solid #5FC56E' : 'transparent' }}
            >
              Categorías
            </button>
            {openTab === 'Categories' &&
            <div 
              className='MoreTabSideBar__sidebar__tabs__container__options'>
              {!isLoadingCategory && filterdeCategoryList.map((item) => (
                <div 
                  className='MoreTabSideBar__sidebar__tabs__container__options__item'
                  key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+item.category}
                  onClick={() => {
                    if (tradeNameParams) {
                      navigate('/categories/category/'+item.category+'/'+tradeNameParams)
                    } else {
                      navigate('/categories/category/'+item.category)
                    }
                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                    dispatch(setMoreTab(false))}}
                >
                  <img src={item.categoryImage} alt="" />
                  {item.category}
                </div>
              ))}
            </div>}
          </div>
          <div 
            className='MoreTabSideBar__sidebar__tabs__container'
          >
            <button
              className='MoreTabSideBar__sidebar__tabs__container__item'
              onClick={() => {setOpenTab(openTab === 'Cuisine' ? '' : 'Cuisine'); }}
              // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
              style={{ borderBottom: openTab === 'Cuisine' ? '1px solid #5FC56E' : 'transparent' }}
            >
              Perfil
            </button>
            {openTab === 'Cuisine' &&
            <div 
              className='MoreTabSideBar__sidebar__tabs__container__options'>
              {!isLoadingBusiness && filterdeBusinessTypeList.map((item) => (
                <div 
                  className='MoreTabSideBar__sidebar__tabs__container__options__item'
                  key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+item.businessType}
                  onClick={() => {
                    if (tradeNameParams) {
                      navigate('/categories/businessType/'+item.businessType+'/'+tradeNameParams)
                    } else {
                      navigate('/categories/businessType/'+item.businessType)
                    }
                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                    dispatch(setMoreTab(false))}}
                >
                  <img src={item.businessTypeImage} alt="" />
                  {item.businessType}
                </div>
              ))}
            </div>}
          </div>
          <div 
            className='MoreTabSideBar__sidebar__tabs__container'
          >
            <button
              className='MoreTabSideBar__sidebar__tabs__container__item'
              onClick={() => {setOpenTab(openTab === 'Brand' ? '' : 'Brand'); }}
              // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
              style={{ borderBottom: openTab === 'Brand' ? '1px solid #5FC56E' : 'transparent' }}
            >
              Marcas
            </button>
            {openTab === 'Brand' &&
            <div 
              className='MoreTabSideBar__sidebar__tabs__container__options'>
              {!isLoadingBrands && filterBrandsList.map((item) => (
                <div 
                  className='MoreTabSideBar__sidebar__tabs__container__options__item'
                  key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+item.brand}
                  onClick={() => {
                    if (tradeNameParams) {
                      navigate('/categories/brand/'+item.brand+'/'+tradeNameParams)
                    } else {
                      navigate('/categories/brand/'+item.brand)
                    }
                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                    dispatch(setMoreTab(false))}}
                >
                  <img src={item.brandImage} alt="" />
                  {item.brand}
                </div>
              ))}
            </div>}
          </div>
          <div 
            className='MoreTabSideBar__sidebar__tabs__container'
          >
            <button
              className='MoreTabSideBar__sidebar__tabs__container__item'
              onClick={() => {setOpenTab(openTab === 'Specialization' ? '' : 'Specialization'); }}
              // onBlur={() => setTimeout(() => setOpenTab(''), 150)}
              style={{ borderBottom: openTab === 'Specialization' ? '1px solid #5FC56E' : 'transparent' }}
            >
              Solucion
            </button>
            {openTab === 'Specialization' &&
            <div 
              className='MoreTabSideBar__sidebar__tabs__container__options'>
              {!isLoading && specializationList.map((item) => (
                <div 
                  className='MoreTabSideBar__sidebar__tabs__container__options__item'
                  key={'MoreTabSideBar__sidebar__tabs__container__options__item-'+item}
                  onClick={() => {
                    if (tradeNameParams) {
                      navigate('/specialization/'+item+'/'+tradeNameParams)
                    } else {
                      navigate('/specialization/'+item)
                    }
                    dispatch(setFiltersSection(['Bar', 'Store', 'Freezer', 'Kitchen']));
                    dispatch(setMoreTab(false))}}
                >
                  {/* <img src={item.brandImage} alt="" /> */}
                  {item}
                </div>
              ))}
            </div>}
          </div>


        </div>
        <div className='MoreTabSideBar__sidebar__category'>
          {/* {buttonsCategories.map((item, index) => (
            <div
              className='MoreTabSideBar__sidebar__category__item'
              key={index+item.name}
              onClick={() => {
                dispatch(setMoreTab(false))
                dispatch(setCategoryButtonsTab(item))
                navigate('/home')}}
            >
              {item.name}
            </div>
          ))} */}
          {/* <div>

          </div> */}
        </div>
        {!openTab && <><div className='MoreTabSideBar__sidebar__settings'>
          <div className='MoreTabSideBar__sidebar__settings__item'>
            <img src={WhiteEarthIcon} alt="" />
            Idioma
            <img src={WhiteArrowDown} alt="" />
          </div>
          
        </div>
        <div className='MoreTabSideBar__sidebar__info'>
          <div className='MoreTabSideBar__sidebar__info__social'>
            <img src={TelegramIcon} alt="Telegram" onClick={() => {window.open('https://t.me/tpobcn', '_blank')}}/>
            <img src={WhatsappIcon} alt="Whatsapp" onClick={() => {window.open('https://wa.me/34642868541', '_blank')}}/>
          </div>
          <div className='MoreTabSideBar__sidebar__info__phone'>
            +34 642 868 541
            <span>Soporte</span>
          </div>
        </div></>}
      </div>
      <div 
        className='MoreTabSideBar__background'
        onClick={() => {dispatch(setMoreTab(false))}}
      ></div>
    </div>
  )
}

export default MoreTabSideBar