import React, { useState, useEffect } from 'react'
import { mainApi } from '../../../../store/services/mainApi'
import {
  MenuItem,
  Modal,
  Box,
  FormControl,
  TextField,
  CircularProgress,
  Typography,
  Switch,
  Checkbox,
  Button,
  InputLabel,
  Select,
} from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { ArrowLeft } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

import './FastOrder.scss'
import { useDispatch } from 'react-redux'

export default function FastOrder({ tradeNames, contactID, sxBtn, }) {
  //fast order

  const mobileView = useMediaQuery('(max-width: 798px)')

  const dispatch = useDispatch()

  const [fastOrderModal, setFastOrderModal] = useState(false)
  const [showPhotos, setShowPhotos] = useState(false)

  const [selectedItems, setSelectedItems] = useState([])

  const { data: contact, isLoading: contactLoading } = mainApi.useGetContactsByIdQuery(contactID, {})

  // const {
  //   data: fastOrderData,
  //   isLoading: fastOrderLoading,
  //   isError: fastOrderError,
  //   isFetching: fastOrderFetching,
  // } = mainApi.useGetFastOrderItemsQuery(
  //   { tradeName },
  //   {
  //     skip: !tradeName && !fastOrderModal,
  //     refetchOnMountOrArgChange: true,
  //   },
  // )

  const {
    data: fastOrderData,
    isLoading: fastOrderLoading,
    isError: fastOrderError,
    isFetching: fastOrderFetching,
  } = mainApi.useGetProductsBySkusQuery(
    { skus: tradeNames ? tradeNames.join(',') : '' },
    {
      skip: !fastOrderModal,
      refetchOnMountOrArgChange: true,
    },
  )

  const [createOrder, { isLoading }] = mainApi.useCreateNewOrderMutation()

  const handleFastOrder = () => {
    setFastOrderModal(true)
  }

  const handleCloseFastOrder = () => {
    setFastOrderModal(false)
  }

  const [selectedWeight, setSelectedWeight] = useState('und') // Начальное значение

  const handleSelectUnit = (item, event) => {
    setSelectedItems(selectedItems.map((i) => (i._id === item._id ? { ...i, currentUnit: event.target.value } : i)))
  }

  const incrementQuantity = (item) => {
    // если продукт уже в списке выбранных, то увеличиваем его количество. Если нет, то добавляем
    if (selectedItems.find((i) => i._id === item._id)) {
      setSelectedItems(selectedItems.map((i) => (i._id === item._id ? { ...i, quantityOrder: i.quantityOrder + 1, quantity: i.quantity + 1 } : i)))
    } else {
      setSelectedItems([...selectedItems, { ...item, quantityOrder: 1, quantity: 1 }])
    }
  }

  const decrementQuantity = (item) => {
    // Проверяем, есть ли продукт в списке выбранных
    const foundItem = selectedItems.find((i) => i._id === item._id)
    if (foundItem) {
      // Если количество больше 1, уменьшаем его. Если равно 1, удаляем продукт из списка
      setSelectedItems(
        selectedItems
          .map((i) => {
            if (i._id === item._id) {
              // Уменьшаем количество, если оно больше 0
              const newQuantityOrder = i.quantityOrder > 1 ? i.quantityOrder - 1 : 0
              return newQuantityOrder > 0 ? { ...i, quantityOrder: newQuantityOrder, quantity: newQuantityOrder } : null
            }
            return i
          })
          ?.filter((i) => i != null), // Удаляем элементы, где количество стало 0
      )
    }
    // Если продукт не найден, ничего не делаем
  }

  // useEffect(() => {
  //   if (fastOrderData) {
  //     console.log(fastOrderData, 'fastOrderData')
  //   }
  // }, [fastOrderData])

  // useEffect(() => {
  //   if (selectedItems) {
  //     console.log(selectedItems, 'selectedItems')
  //   }
  // }, [selectedItems])

  const handleCreateOrder = async () => {
    let body = {
      data: {
        items: selectedItems.map((el) => {
          return { ...el }
        }),
        date: Date.now(),
        type: 'browser',
        status: {},
        paidValue: 0,
        userAcc: {
          ...contact,
        },
        tradeName: contact?.tradeName ? contact?.tradeName : 'Not Set',
        description: 'Favorite order',
      },
    }
    await createOrder(body)
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    handleCloseFastOrder()
  }

  return (
    <Box>
      <Button size='small' onClick={handleFastOrder} variant='contained' sx={sxBtn}>
        Repetir 🔁
      </Button>

      <Modal open={fastOrderModal} onClose={handleCloseFastOrder}>
        <Box
          className='cartModal'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 350,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '500px',
            overflowY: 'auto',
            fontFamily: 'Montserrat',
          }}
        >
          <div className='cartModal__header'>
            <div className='cartModal__header__back'>
              <ArrowLeft color='success' />
              Back
            </div>
            <div className='cartModal__header__proMod'>
              <Typography sx={{ fontFamily: 'Montserrat' }}>Pro Mode (W/O Photo)</Typography>
              <Switch value={showPhotos} onChange={() => setShowPhotos(!showPhotos)} />
            </div>
          </div>
          <div className='cartModal__body'>
            {fastOrderFetching ? (
              <CircularProgress />
            ) : fastOrderData ? (
              fastOrderData.map((item) => (
                <div key={item._id} className='cartModal__body__item'>
                  {/* <Checkbox checked={selectedItems.some((i) => i._id === item._id)} onChange={() => selectItem(item)} /> */}
                  <div className='cartModal__body__item__row'>
                    <img
                      src={item.img}
                      width={'50px'}
                      height={'50px'}
                      alt='item'
                      style={{ width: '50px', height: '50px', display: showPhotos ? 'block' : 'none' }}
                    />
                    <div className='cartModal__body__item__row__info'>
                      {item.name}
                      <div className='cartModal__body__item__row__info__sku'>{item.sku}€</div>
                    </div>
                  </div>
                  <div className='cartModal__body__item__row'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #ccc',
                          padding: '5px',
                          borderRadius: '32px',
                          backgroundColor: '#f5f4f2',
                          zIndex: 1,
                        }}
                      >
                        <RemoveOutlinedIcon
                          onClick={() => decrementQuantity(item)}
                          sx={{
                            color: '#5fc56e',
                            cursor: 'pointer',
                          }}
                        />
                        <span
                          style={{
                            color: '#5fc56e',
                          }}
                        >
                          {
                            // find the item in the selectedItems array by _id
                            selectedItems.find((i) => i._id === item._id)?.quantity || 0
                          }
                        </span>

                        <AddOutlinedIcon
                          onClick={() => incrementQuantity(item)}
                          sx={{
                            color: '#5fc56e',
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: '32px',
                          borderTopLeftRadius: '0px',
                          borderBottomLeftRadius: '0px',
                          height: '32px',
                          marginLeft: '-10px',
                          paddingLeft: '25px',
                          paddingRight: '15px',
                          borderLeft: '0px',
                          backgroundColor: '#5fc56e',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          color: '#fff',
                          position: 'relative',
                        }}
                      >
                        {item.unit.includes(',') ? (
                          <Select
                            value={selectedItems.find((i) => i._id === item._id)?.currentUnit || item.unit.split(',')[0]} // The state that holds the selected weight
                            onChange={(event) => handleSelectUnit(item, event)} // Function to handle the change of selection
                            IconComponent={KeyboardArrowDownOutlinedIcon} // Icon for the dropdown list
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} // Dropdown list properties
                            variant='standard' // Component variant
                            inputProps={{ style: { fontFamily: 'Montserrat', fontSize: '12px' } }} // Input component properties
                            disableUnderline
                            sx={{
                              color: '#fff', // Text color
                              '& .MuiSvgIcon-root': {
                                color: '#fff', // Icon color
                              },
                              '& .MuiInputBase-root': {
                                '&&&:before': {
                                  borderBottom: 'none', // Ensure underline is removed
                                },
                                '&&:after': {
                                  borderBottom: 'none', // Ensure underline is removed
                                },
                              },
                            }}
                          >
                            {item.unit.split(',').map((weight) => (
                              <MenuItem key={weight} value={weight}>
                                {weight}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          item.unit
                        )}
                      </Box>
                    </Box>
                    <span>{item.marketPriceCP} €</span>
                  </div>
                  {/* <Typography sx={{ fontFamily: 'Montserrat', flex: 1 }}>{item.currentUnit}</Typography> */}
                  {/* <Typography sx={{ fontFamily: 'Montserrat', flex: 1 }}>{item.marketPriceCP}€</Typography> */}
                  {/* {selectedItems.some((i) => i._id === item._id) ? (
                    <TextField
                      size='small'
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ width: '40' }}
                      variant='standard'
                      value={selectedItems.find((i) => i._id === item._id).quantity}
                      onChange={(e) => changeQuantity(item, e.target.value)}
                    />
                  ) : null}
                  {selectedItems.some((i) => i._id === item._id) ? (
                    <Typography sx={{ fontFamily: 'Montserrat', flex: 1 }}>
                      {(
                        (selectedItems.find((i) => i._id === item._id)?.quantity || 0) *
                        (selectedItems.find((i) => i._id === item._id)?.marketPriceCP || 0)
                      ).toFixed(2)}
                      €
                    </Typography>
                  ) : null} */}
                </div>
              ))
            ) : (
              <p>no data</p>
            )}
          </div>
          <Box
            className='cartModal__footer'
            // sx={{
            //   display: 'flex',
            //   justifyContent: 'flex-end',
            //   alignItems: 'center',
            //   position: 'sticky',
            //   bottom: '0',

            //   width: '100%',
            //   height: '50px',
            //   marginTop: '20px',
            // }}
          >
            <div className='cartModal__footer__total'>
              Total{' '}
              {selectedItems
                .map((item) => (item.marketPriceCP ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
                .reduce((acc, num) => acc + num, 0)
                .toFixed(2)}
              €
              <Button
                onClick={handleCreateOrder}
                className='cartModal__footer__total__btn'
                sx={{ width: '230px' }}
                variant='contained'
                color='success'
              >
                Create order
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
