import React, { useState, useEffect} from 'react'
import './CounterModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import arrowDownCard from '../../../assets/images/arrow-down-card-unit.svg'
import { 
  setCartElemQuantityIncrement, 
  setCartElemCurrentUnit, 
  setCartElemQuantityValue,
 } from '../../../../../store/slices/allItemsCart'
import { setAllItemsCart } from '../../../../../store/slices/allItemsCart'
import useFindInCart from '../../../../../hooks/useFindInCart'

const CounterModal = ({ item }) => {
  const [cardUnits, setCardUnits] = useState(item?.unit == undefined ? ['none'] : item?.unit?.split(', '))
  const dispatch = useDispatch()
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const grammMode = useSelector((state) => state.contextUI.grammMode)
  
  const inCart = useFindInCart(item)
  
  const [showQuantity, setShowQuantity] = useState()
  const [itemSettings, setItemSettings] = useState({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})
  
  
  useEffect(() => {
    setItemSettings({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})
    setShowQuantity(grammMode ? (parseFloat((inCart?.quantity ? inCart.quantity*1000 : 0))) : (parseFloat((inCart?.quantity ? inCart.quantity : 0))))
  }, [inCart, grammMode])
  console.log(inCart?.quantity*1000);
  
  const handleChangeUnit = (unit) => {
    setShowUnits(false)
    setItemSettings({...itemSettings, unit: unit})
    dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: unit }))
  }
  
  const handleSetCount = (value) => {
    setShowQuantity(parseFloat(value))
    if (grammMode) {
      setItemSettings({...itemSettings, quantity: parseFloat(value)/1000})
      !inCart && (parseFloat(value)/1000) > 0 && dispatch(setAllItemsCart(item))
      inCart && (parseFloat(value)/1000) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value)/1000, elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      setItemSettings({...itemSettings, quantity: parseFloat(value)})
      !inCart && parseFloat(value) > 0 && dispatch(setAllItemsCart(item))
      inCart && parseFloat(value) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }}
  const handleDecrement = () => {
    if (grammMode) {
      if (itemSettings.quantity > 0) {
        setItemSettings({...itemSettings, quantity: itemSettings.quantity - 0.1})
      }
      if (itemSettings.quantity <= 0.1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: (itemSettings.quantity-0.1).toFixed(1), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      if (itemSettings.quantity > 0) {
        setItemSettings({...itemSettings, quantity: itemSettings.quantity - 1})
      }
      if (itemSettings.quantity <= 1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: itemSettings.quantity-1, elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }
  const handleIncrement = () => {
    if (grammMode) {
      !inCart && dispatch(setAllItemsCart(item))
      inCart && dispatch(setCartElemQuantityValue({ value: (itemSettings.quantity+0.1).toFixed(1), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      !inCart && dispatch(setAllItemsCart(item))
      inCart && dispatch(setCartElemQuantityIncrement({ sku: item?.sku }))  
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }

  const [showUnits, setShowUnits] = useState(false)
  return (
    <div className='CounterModal'>
      <div className='CounterModal__select'>
        <div className='CounterModal__select__controller'>
          <div 
            onClick={handleDecrement}
            className='CounterModal__select__controller__button'>-</div>
          <div className='CounterModal__select__controller__quantity'>
            <input 
            type="number" 
            value={showQuantity}
            onChange={(e) => {handleSetCount(e.target.value)}}
            onBlur={(e) => {e.target.value == '' ? handleSetCount(0) : handleSetCount(e.target.value)}}
            />
          </div>
          <div 
            onClick={handleIncrement}
            className='CounterModal__select__controller__button'>+</div>
        </div>
        <div className='CounterModal__select__unit'>
          <button 
            onClick={() => {setShowUnits(!showUnits)}} 
            // onBlur={() => {setTimeout(() => {setShowUnits(false)}, 100)}}
            >
            {itemSettings.unit}
            <img src={arrowDownCard} alt="" />
          </button>
          {showUnits && 
          <div className='CounterModal__select__unit__options'>
            {item.unit?.split(', ').length>1 ? (
                item.unit?.split(', ').map((el, index) => <>
                {index !== 0 && <pre style={{margin: '0'}}> | </pre>}
                <div 
                  key={el+item.sku+'CounterModalMap'}
                  className='CounterModal__select__unit__options__item' 
                  onClick={() => {handleChangeUnit(el)}}
                  >{el}</div></>)
              ) : (
                <div 
                  key={item.sku+'CounterModalMap'}
                  className='CounterModal__select__unit__options__item' 
                  onClick={() => {handleChangeUnit(item.unit)}}
                  >{itemSettings.unit || item.unit}</div>
              )}
          </div>}
        </div>
      </div>
    </div>
  )
}

export default CounterModal