import React, { useEffect } from 'react'
import CloseIcon from '../../assets/images/closeElem.svg'
import { useDispatch } from 'react-redux'
import { setWarnModal } from '../../../../store/slices/contextUI'
import { Box, Button, CircularProgress, Modal } from '@mui/material'

const ClassicModal = ({children, timeout, open, close}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (timeout) {clearTimeout()
    setTimeout(() => {
      dispatch(setWarnModal(false))
    }, timeout)}
  }, [])

  return !open ? <></> : 
  <Modal open={open} onClose={() => close(false)}>
  <Box 
  className='NewOrderPage__mainEditOrder__sendOrder__modal'
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    border: '3px solid #5FC56E',
    fontFamily: 'Montserrat',
    fontSize: '17px',
    color: '#5FC56E',
    borderRadius: 6,
    gap: 2,
    p: 4,
  }}>
    {children}
    <Button
      variant='contained'
      sx={{
        width: '100%',
        fontFamily: 'Montserrat',
        backgroundColor: '#5FC56E',
        '&:hover': {
          backgroundColor: '#5FC56E',
        },
      }}
      onClick={() => {
        close(false)
      }}
    >
      Confirmar
    </Button>
  </Box> 
</Modal>
  
}

export default ClassicModal