import React from 'react'
import './OrderItem.scss'
import CompliteSquare from '../../../../assets/images/Complite-Square-2.svg'
import TimeSquare from '../../../../assets/images/Time-Square-2.svg'
import CloseSquare from '../../../../assets/images/Close-Square-2.svg'
import ArrowRigth from '../../../../assets/images/ArrowRight.svg'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { setItemsCart } from '../../../../../../store/slices/allItemsCart'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


const OrderItem = ({ item }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    return formattedDate;
  }
  const totalPrice = item.items.map((el) => el.marketPriceCP * el.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const handleRepidPedido = (item) => {
    //   console.log(item.items);
      dispatch(setItemsCart(item.items))
      handleClick()
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }
  const action = (
    <div className='Snackbar'>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };


  return (
    <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item">
        <Snackbar
            className='Snackbar'
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Artículos agregados al carrito"
            action={action}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            TransitionComponent={SlideTransition}
        />
        <img
            src={item.status.name === 'Emitido' ? CompliteSquare : item.status === 'Waiting' ? TimeSquare : CloseSquare}
            alt=""
            className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__status"
        />
        <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__column">
            <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__name">
                {item.status.name === 'Emitido' ? 'Waiting' : item.status === 'Waiting' ? 'sd' : 'Close'}
            </div>
            <div 
                    className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__btn"
                    onClick={() => handleRepidPedido(item)}
                >
                Repetir pedido
            </div>
        </div>
        <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__column">
            <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__number">
                Pedido: <span>{item.satus?.name}</span>
            </div>
            <div
                className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__btn"
                onClick={() => navigate(`/order/${item._id}`)}
            >
                Mas informacion
                <img src={ArrowRigth} alt="" />
            </div>
        </div>
        <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__column">
            <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__number">
                Fecha: <span>{convertTimestampToDate(item.date)}</span>
            </div>
            <div className="Profile__mainPage__section__mainSection__item__ordersSection__content__item__info__row__price"
            >
                {totalPrice?.toFixed(2)}€
            </div>
        </div>
    </div>
  )
}

export default OrderItem