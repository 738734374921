import React, {useState, useEffect} from 'react'
import './CatalogModal.scss'
import { mainApi } from '../../../../store/services/mainApi'
//import ContentCard from '../../Content/ContentCard/ContentCard'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ItemModal from '../ItemModal/ItemModal'
import { CircularProgress } from '@mui/material'


const CatalogModal = ({setCatalogModal}) => {
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const [catigories, setCatigories] = React.useState([])
  const [itemData, setItemData] = React.useState()

  const ItemModalShow = (item) => {
    setItemData(item)
    setModal(true)
    
  }

  useEffect(() => {
    if (allProductsData) {
      setCatigories([...new Set(allProductsData.map((item) => item.category))])
    }
  }, [allProductsData])

  const [currentMenuPoint, setCurrentMenuPoint] = useState('')
  const { categoryName, categoryType } = useParams()
  const search = useSelector((store) => store.search.search)

  const [modal, setModal] = useState(false)

  return (
    <div className='mainCatalog' >
      <div className="mainCatalog__background" onClick={() => setCatalogModal(false)}></div>
      <div className="catalogModal">
        
        <div className='categoriesPage'>
          {isLoading
            ? <CircularProgress/>
            : categoryName
            ? allProductsData.map(
                (item) =>
                  item.name.toLowerCase().includes(search.toLowerCase()) && item[categoryType]?.includes(categoryName),
              )
            : catigories?.slice(0,10).map((item) => (
              <div
                key={item}
                className='categoriesPage__category'
                onClick={() => setCurrentMenuPoint(item)}
                style={{ background: currentMenuPoint == item && '#5FC56E', color: currentMenuPoint == item && '#fff' }}
                >{item}
              </div>
            ))}
        </div>
        
              
        
      </div>
      {currentMenuPoint != '' && <div className="catalogModal__additionSection">
          {isLoading
          ? 'Loading'
          : categoryName
          ? allProductsData.map((item) => item.category.includes(currentMenuPoint))
          : allProductsData.map((item) => item.category.includes(currentMenuPoint) && (
            <div
              key={item.sku}
              className='catalogModal__additionSection__item'
              onClick={() => (
                  setModal(true),
                  setItemData(item)
                )}
              
              >
                <div className='catalogModal__additionSection__item__rectengle'></div>
                {item.name}
            </div>
            
          ))}
          {modal && <ItemModal setModal={setModal} card={itemData } />}
      </div>}
        
    </div>
  )
}

export default CatalogModal
