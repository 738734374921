import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  itemsCart: localStorage.getItem('itemsCart') ? JSON.parse(localStorage.getItem('itemsCart')) : [],
}

export const itemsCartSlice = createSlice({
  name: 'itemsCart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.itemsCart = [...action.payload]
    },
    setClearCart: (state, action) => {
      state.itemsCart = []
    },
    setItemsCart: (state, action) => {
      const filterArray = state.itemsCart?.map((elem) => elem.sku)
      if (!filterArray?.includes(action.payload.sku)) {
        if (action.payload.quantity === 0) {
          state.itemsCart = [...state.itemsCart, { sku: action.payload.sku, quantity: 1, quantityOrder: 1, currentUnit: action.payload.currentUnit }]
        } else {
          state.itemsCart = [...state.itemsCart, action.payload]
        }
      } else {
        state.itemsCart = state.itemsCart.filter((item) => item.sku !== action.payload.sku)
      }
    },
    delCartElem: (state, action) => {
      state.itemsCart = state.itemsCart.filter((item) => item?.sku !== action.payload.sku)
    },
    setCurrentUnitElemCart: (state, action) => {
      const itemIndex = state.itemsCart.findIndex((item) => item?.sku === action.payload.elem.sku)
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].currentUnit = action.payload.value
      }
    },
    setQuantityValueElemCart: (state, action) => {
      const itemIndex = state.itemsCart.findIndex((item) => item?.sku === action.payload.elem?.sku)
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantity = parseFloat(action.payload.value)
      }
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantityOrder = parseFloat(action.payload.value)
      }
    },
    setQuantityIncrementElemCart: (state, action) => {
      const itemIndex = state.itemsCart.findIndex((item) => item.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantity = parseFloat(state.itemsCart[itemIndex].quantity)
          ? parseFloat(state.itemsCart[itemIndex].quantity) + 1
          : 1
      }
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantityOrder = parseFloat(state.itemsCart[itemIndex].quantityOrder)
          ? parseFloat(state.itemsCart[itemIndex].quantityOrder) + 1
          : 1
      }
    },
    setQuantityDecrementElemCart: (state, action) => {
      const itemIndex = state.itemsCart.findIndex((item) => item?.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantity = parseFloat(state.itemsCart[itemIndex].quantity)
          ? parseFloat(state.itemsCart[itemIndex].quantity) - 1
          : 0
      }
      if (parseFloat(state.itemsCart[itemIndex].quantity) <= 0) {
        state.itemsCart[itemIndex].quantity = 0
      }
      if (itemIndex >= 0) {
        state.itemsCart[itemIndex].quantityOrder = parseFloat(state.itemsCart[itemIndex].quantityOrder)
          ? parseFloat(state.itemsCart[itemIndex].quantityOrder) - 1
          : 0
      }
      if (parseFloat(state.itemsCart[itemIndex].quantityOrder) <= 0) {
        state.itemsCart[itemIndex].quantityOrder = 0
      }
    },
  },
})

export const {
  setCart,
  setClearCart,
  setItemsCart,
  delCartElem,
  setCurrentUnitElemCart ,
  setQuantityValueElemCart,
  setQuantityIncrementElemCart,
  setQuantityDecrementElemCart,
} = itemsCartSlice.actions

export default itemsCartSlice.reducer
