import BarIcon from '../pages/components/assets/images/BarIcon.svg'
import NeveraIcon from '../pages/components/assets/images/NeveraIcon.svg'
import AlmacenIcon from '../pages/components/assets/images/AlmacenIcon.svg'
import FrescoIcon from '../pages/components/assets/images/FreskoIcon.svg'
import CongeladorIcon from '../pages/components/assets/images/CongeladorIcon.svg'

export const buttonsKitchen = [
    {
        icon: FrescoIcon,
        img: 'https://i.imgur.com/LjJTyo1.png',
        name: 'Fruit & Vegetables',
    },
    {
        icon: NeveraIcon,
        img: 'https://i.imgur.com/fFcLNnA.png',
        name: 'Proteína',
    },
]
const buttonsCategories = [
    {
        icon: FrescoIcon,
        img: 'https://i.imgur.com/LjJTyo1.png',
        name: 'Cocina',
        buttons: buttonsKitchen,
        tab: 'Kitchen',
    },
    {
        icon: BarIcon,
        img: 'https://i.imgur.com/bBwBHne.png',
        name: 'Cocteleria',
        tab: 'Bar',
    },
    {
        icon: AlmacenIcon,
        img: 'https://i.imgur.com/7ixIgcD.png',
        name: 'Despensa',
        tab: 'Store',
    },
    // {
    //     icon: NeveraIcon,
    //     img: 'https://i.imgur.com/8tPmkfV.png',
    //     name: 'Proteína'
    // },
    {
        icon: CongeladorIcon,
        img: 'https://i.imgur.com/PE7VAY3.png',
        name: 'Congelador',
        tab: 'Kitchen',
    },
]

export default buttonsCategories