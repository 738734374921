import React from 'react'
import './ProfileLanguages.scss'
import ProfileSideBar from '../SideBar/ProfileSideBar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ProfileLanguages = () => {
  return (
    <div className='ProfileLanguages__container'>
      <div className='ProfileLanguages__container__section'>
        <div className='ProfileLanguages__container__section__item__left'>
          <ProfileSideBar/>
        </div>
        <div className='ProfileLanguages__container__section__item__right'>
          <div className="Profile__mainPage__section__mainSection__item__language">
            <span>Chose the language</span>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={['Ru', 'En', 'Fr', 'Es']}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileLanguages