import { createSlice } from '@reduxjs/toolkit'

const initialState = {
filterData: {
    filterDataAkcent: [],
    filterDataSearch: [],
    filterType: 'Product group',
    filters: {
      category: [],
      specialization: [],
      section: [],
      brand: [],
      businessType: [],
      priceLow: 0,
      priceHigh: 100,
      spo: false,
      und: [],
    }
  }
}


export const createFilterDataSlice = createSlice({
  name: 'FilterData',
  initialState,
  reducers: {
    setFilters: (state = initialState, action) => {
      state.filterData = action.payload
    },
    setFilterType(state = initialState, action) {
      state.filterData.filterType = action.payload
    },
    setFiltersBrand(state = initialState, action) {
      state.filterData.filters.brand = action.payload
    },
    setFiltersBusinessType(state = initialState, action) {
      state.filterData.filters.businessType = action.payload
    },
    setFiltersPriceLow(state = initialState, action) {
      state.filterData.filters.priceLow = action.payload
    },
    setFiltersPriceHigh(state = initialState, action) {
        state.filterData.filters.priceHigh = action.payload
    },
    setBusinessType(state = initialState, action) {
        state.filterData.filters.businessType = action.payload
    },
    setFiltersSPO(state = initialState, action) {
        state.filterData.filters.spo = action.payload
    },
    setFiltersUnd(state = initialState, action) {
        state.filterData.filters.und = action.payload
    },
    setFilterSpecialization(state = initialState, action) {
        state.filterData.filters.specialization = action.payload
    },
    setFilterCategory(state = initialState, action) {
        state.filterData.filters.category = action.payload
    },
    setFiltersSection(state = initialState, action) {
        state.filterData.filters.section = action.payload
    },
    setDataSearch(state = initialState, action) {
        state.filterDataSearch = action.payload
    },
    setClearFilters(state = initialState, action) {
        state.filterData.filters = {
          category: [],
          specialization: [],
          section: [],
          brand: [],
          businessType: [],
          priceLow: 0,
          priceHigh: 100,
          spo: false,
          und: [],
        }
    }
  },
})

export const {
  setFilters, 
  setFilterType,
  setFiltersBrand,
  setFiltersBusinessType,
  setFiltersPriceLow,
  setFiltersPriceHigh,
  setBusinessType,
  setFiltersSPO,
  setFiltersUnd,
  setFilterSpecialization,
  setFilterCategory,
  setFiltersSection,
  setClearFilters,
  setDataSearch
} = createFilterDataSlice.actions

export default createFilterDataSlice.reducer
