import React, { useEffect, useState, useMemo } from 'react'
import './SpecializationPage.scss' 
import { useNavigate, useParams } from 'react-router-dom'
import { mainApi } from '../../store/services/mainApi'
import CategoryItem from '../components/Content/Categories/CategoryItem/CategoryItem'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterCategory, setFilterSpecialization } from '../../store/slices/filterData'

const SpecializationPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { specializationName, tradeName } = useParams()
  const filters = useSelector((state) => state.filterData.filterData.filters)
  const [allProductFilterSpec, setAllProductFilterSpec] = useState([])
  const [categoryFilterSpec, setCategoryFilterSpec] = useState([])
  const [mapList, setMapList] = useState([])
  
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  })
  const { data: categoryList, isLoading: isCategoryLoading } = mainApi.useGetCategoryListQuery()
  const allSectionProducts = allProductsData?.filter(product => product.section?.find(el => filters.section.includes(el) || true))
  
  useEffect(() => {
    if (!isLoading && !isLoading) 
    {
      const allFilterdata = allSectionProducts?.filter(el => el.specialization === specializationName && el.enabled).map(el => el.category)
      const categoryProductList = Array.from(new Set(allFilterdata))

      
      const newCategoryList = categoryList?.filter(el => categoryProductList.includes(el.category))
      if (newCategoryList.length === 1) {
        navigate(`/specialization/${specializationName}/${newCategoryList[0].category}`)
      }
      console.log(newCategoryList);
      setMapList(newCategoryList)
      // console.log(newCategoryList);
    }
  }, [allProductsData, categoryList, specializationName, isLoading, isCategoryLoading])

  return (
    <div className='categoriesMain'>
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{specializationName}</div>
      </div>
      <div className='categoriesPage'>
        {!isLoading && !isCategoryLoading && mapList.map((item) => (
          <div 
            className='PreviewList__categories__item'
            style={{fontFamily: 'Montserrat'}}
            key = {item.category}
            onClick={() => {
              if (tradeName) {
                navigate(`/specialization/${specializationName}/${item.category}/${tradeName}`)
              } else {
                navigate(`/specialization/${specializationName}/${item.category}`)
              }
            }}
          >
            <img src={item.categoryImage} alt="" />
            {item.category}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SpecializationPage


